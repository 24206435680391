var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publication-header" }, [
    _c("div", { staticClass: "publication-info" }, [
      _c("div", { staticClass: "publication-info-section" }, [
        _c(
          "div",
          { staticClass: "publisher-photo" },
          [
            _c("icon-image", {
              attrs: { src: _vm.publisherPhoto, text: _vm.publisherName },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "publication-info-section grow-section" }, [
        _c("div", { staticClass: "publisher-name" }, [
          _vm._v(_vm._s(_vm.publisherName)),
        ]),
        _c("div", { staticClass: "published-time" }, [
          _vm._v(_vm._s(_vm.publishedTime)),
        ]),
      ]),
      _c("div", { staticClass: "publication-info-section" }, [
        _c(
          "div",
          {
            staticClass: "publication-menu circle-animation",
            on: { click: _vm.doMenu },
          },
          [_c("i", { staticClass: "far fa-ellipsis-v" })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-list" },
    [
      _c("div", { staticClass: "side-header" }, [
        _c("div", { staticClass: "section-header" }, [
          _c("h4", { staticClass: "header-text" }, [
            _vm._v(_vm._s(_vm.$t("settings.chats"))),
          ]),
          _c("div", { staticClass: "header-btns" }, [
            _c(
              "div",
              {
                staticClass: "circle-animation",
                attrs: { title: _vm.$t("mainPage.menu") },
                on: {
                  click: function ($event) {
                    return _vm.doMenu($event, "bottom-right")
                  },
                },
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } }),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.side_notification ? _c("side-notification-bar") : _vm._e(),
      _c("div", { staticClass: "side-body" }, [
        _c("div", { staticClass: "side-list" }, [
          _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.chats, function (chat) {
              return _vm.chats.length
                ? _c(
                    "li",
                    [
                      "cid" in chat
                        ? _c("chat-item", {
                            attrs: { chat: chat },
                            on: {
                              dragtochat: function ($event) {
                                return _vm.dragToChat($event)
                              },
                              dragleavechat: function ($event) {
                                return _vm.dragLeaveChat($event)
                              },
                            },
                          })
                        : chat.chId
                        ? _c("channel-item", {
                            attrs: { channel: chat },
                            on: {
                              dragtochat: function ($event) {
                                return _vm.dragToChat($event)
                              },
                              dragleavechat: function ($event) {
                                return _vm.dragLeaveChat($event)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "hor-line light-line" }),
                    ],
                    1
                  )
                : _c("li", [_vm._v(_vm._s(_vm.$t("no-chats")))])
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "source-select-wrapper" }, [
    _vm.sourceSelect
      ? _c("div", { staticClass: "source-select" }, [
          _c(
            "div",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  return _vm.$emit("source-select-canceled")
                },
              },
            },
            [_c("i", { staticClass: "fal fa-times" })]
          ),
          _c(
            "div",
            { staticClass: "sources" },
            _vm._l(_vm.sources, function (source) {
              return _c("div", { key: source.id, staticClass: "source" }, [
                _c("div", { staticClass: "img-wrapper" }, [
                  _c("img", {
                    attrs: { src: source.img },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("source-selected", source.id)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "text" }, [
                  _c("span", [_vm._v(_vm._s(source.name))]),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
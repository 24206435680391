//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { RADIO, SOCKET, USERDATA } from '../../store/modulesNames'
import {
    GET_CONNECTION_STATUS,
    GET_RADIO_SETTINGS,
    GET_SPEECH_BUFFER_EMPTY,
    RADIO_IS_TURN_ON,
    GET_ACTIVE_MICROPHONE,
    IS_MICROPHONE_ENABLED,
    GET_CURRENT_RADIO,
} from '../../store/gettersTypes'
import InfoSection from "./InfoSection.vue"
import RadioBtns from "./RadioBtns.vue"
import CancelBtn from "./CancelBtn.vue"
import PttButton from "./PttButton.vue"
import RadioStatus from "./RadioStatus.vue"
import RadioSettings from "../modal/RadioSettings.vue"
import { PTT_BTN_MODES } from '../../store/modules/radio'
import {
    ACT_CHECK_MEDIA_DEVICES,
    ACT_SET_PTT_POP_AWAITING,
} from "../../store/actionsTypes"

import {RADIO_TYPE} from '../../store/modules/radio'

export default {
    name: "RadioComponent",
    components: { InfoSection, RadioBtns, CancelBtn, PttButton, RadioStatus },
    mounted() {
        this.onMounted()
    },
    data() {
        return {
            notifyTimeOut: 0,
            notifyClass: '',
            notifyText: '',
            pttIsPushed: false,
            pttPopTimeOut: 0
        }
    },
    computed: {
        _connected() {
            return this.connected && this.socketConnected
        },
        _title() {
            return this.socketConnected || !this[RADIO_IS_TURN_ON] ? this.title : this.$t('no-connection')
        },
        _subTitle() {
            return this.socketConnected || !this[RADIO_IS_TURN_ON] ? this.subTitle : ''
        },
        socketConnected() {
            return this[GET_CONNECTION_STATUS] === 'connected'
        },
        connected: () => false,
        isTetra() {
            return this[GET_CURRENT_RADIO].type === RADIO_TYPE.TETRA
        },
        title: () => '',
        subTitle: () => '',
        pttStatus: () => '',
        pttPushed: () => false,
        infoText: () => '',
        missedMsgsCount: () => '',
        pttBtnMode() { return this[GET_RADIO_SETTINGS].pttBtnMode },
        isMicEnabled() { return Boolean(this[GET_ACTIVE_MICROPHONE] && this[GET_ACTIVE_MICROPHONE].deviceId) },
        ...mapGetters(SOCKET, [GET_CONNECTION_STATUS]),
        ...mapGetters(RADIO, [RADIO_IS_TURN_ON, GET_RADIO_SETTINGS, GET_SPEECH_BUFFER_EMPTY, GET_CURRENT_RADIO]),
        ...mapGetters(USERDATA, [GET_ACTIVE_MICROPHONE, IS_MICROPHONE_ENABLED]),
    },
    methods: {
        async onClickPTT() {
            if (!(await this.isMicOk())) {
                this.setPttPushed(false)
            }
            else if (this.pttBtnMode === PTT_BTN_MODES.SWITCH) this.setPttPushed(!this.pttIsPushed)
        },
        async onPTTPush() {
            if  (await this.isMicOk() && this.pttBtnMode === PTT_BTN_MODES.DEFAULT) {
                this.setPttPushed(true)
            } 
        },
        onPTTPop() {
            if (this.pttBtnMode === PTT_BTN_MODES.DEFAULT) this.setPttPushed(false)
        },
        setPttPushed(pushed = false) {
            this.clearPttPopTimeOut()
            if (!pushed) {
                if (this.pttIsPushed) {
                    this[ACT_SET_PTT_POP_AWAITING](true)
                    this.pttPopTimeOut = setTimeout(() => {
                        console.log('ptt popped by timeout')
                        this.pttIsPushed = pushed
                        this.clearPttPopTimeOut()
                    }, 500)
                }
            } else {
                this[ACT_SET_PTT_POP_AWAITING](false)
                this.pttIsPushed = pushed
            }
        },
        clearPttPopTimeOut() {
            if (this.pttPopTimeOut) {
                clearTimeout(this.pttPopTimeOut)
                this.pttPopTimeOut = 0
            }
        },
        pushPTT: () => {},
        popPTT: () => {},
        onGroupSelect: () => {},
        onRepeatLast: () => {},
        onStartChat: () => {},
        onInfo: () => {},
        onExit: () => {},
        onMounted: () => {},
        setNotify(text) {
            clearTimeout(this.notifyTimeOut)
            if (text) {
                this.notifyText = text
                this.notifyClass = 'show'
                this.notifyTimeOut = setTimeout(() => {
                    clearTimeout(this.notifyTimeOut)
                    this.notifyClass = ''
                }, 5000)
            } else {
                this.notifyClass = ''
            }
        },
        playAudioNotify() {
            this.$refs.audioNotify.play()
        },
        onSettings() {
            this.modalOpen({
                component: RadioSettings,
            })
        },
        async isMicOk() {
            let result
            let enabled = this[IS_MICROPHONE_ENABLED]
            try {
                result = (await this[ACT_CHECK_MEDIA_DEVICES]({audio: true, checkInterval: 5 * 60})).audio
            } catch (e) {
                result = false
            }
            return enabled && result
        },
        ...mapActions(RADIO, [ACT_SET_PTT_POP_AWAITING]),
        ...mapActions(USERDATA, [ACT_CHECK_MEDIA_DEVICES]),
    },
    watch: {
        pttIsPushed(val) {
            if (val) this.pushPTT()
            else this.popPTT()
        },
        _connected(val) {
            if (!val) this.pttIsPushed = false
        },
        [GET_SPEECH_BUFFER_EMPTY](val) {
            if (val && this.pttPopTimeOut) {
                console.log('ptt popped by empty buffer')
                this.pttIsPushed = false
                this.clearPttPopTimeOut()
            }
        },
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "grid-member",
      class: _vm.classes,
      style: _vm.styles,
      on: {
        contextmenu: function ($event) {
          return _vm.doMenu($event)
        },
      },
    },
    [
      _c("div", { staticClass: "reactions-wrapper" }, [
        _vm.handup
          ? _c("div", {
              staticClass: "reaction",
              domProps: { innerHTML: _vm._s(_vm.handupIcon) },
            })
          : _vm._e(),
        _vm.reaction
          ? _c("div", {
              staticClass: "reaction",
              domProps: {
                innerHTML: _vm._s(_vm.getReactionHtml(_vm.reaction)),
              },
            })
          : _vm._e(),
      ]),
      _vm.moderator
        ? _c(
            "div",
            {
              staticClass: "more-wrapper",
              on: {
                click: function ($event) {
                  return _vm.doMenu($event, "bottom-left")
                },
              },
            },
            [_c("i", { staticClass: "fal fa-ellipsis-h fa-rotate-90" })]
          )
        : _vm._e(),
      !_vm.cameraIsOn
        ? _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))])
        : _vm._e(),
      _c("div", { staticClass: "status-wrapper" }, [
        _c("div", { staticClass: "microphone status-icon" }, [
          _c("i", { class: _vm.microphoneIconClasses }),
        ]),
        _c("div", { staticClass: "camera status-icon" }, [
          _c("i", { class: _vm.cameraIconClasses }),
        ]),
        _vm.cameraIsOn
          ? _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-central" },
    _vm._l(_vm.modals, function (modal, index) {
      return _c(
        modal.component || modal.name,
        _vm._b(
          {
            key: index,
            tag: "component",
            attrs: {
              "modal-name": modal.name,
              props: modal.props,
              "modal-props": modal.modalProps,
            },
          },
          "component",
          modal.props || {},
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-conference-new",
      class: { "video-conf-fullscreen": _vm.videoConfFullScreen },
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "video-conf-section-header",
        _vm._b(
          {
            on: {
              "toggle-info": _vm.toggleInfo,
              "toggle-scheme-select": _vm.toggleSchemeSelect,
              "toggle-exit": _vm.toggleExit,
            },
          },
          "video-conf-section-header",
          _vm.sectionBindProps,
          false
        )
      ),
      _c(
        "video-conf-section-main",
        _vm._b(
          {
            attrs: { "members-list-opened": _vm.membersListOpened },
            on: {
              ready: _vm.onVideoCompReady,
              "member-menu": _vm.doMenu,
              "members-open": _vm.membersOpen,
            },
          },
          "video-conf-section-main",
          _vm.sectionBindProps,
          false
        )
      ),
      _c(
        "video-conf-section-footer",
        _vm._b(
          {
            on: {
              "members-open": _vm.membersOpen,
              "toggle-want-speck": _vm.toggleWantSpeck,
              "toggle-cam-list": _vm.toggleCamList,
              "toggle-mic-list": _vm.toggleMicList,
              "toggle-reaction-select": _vm.toggleReactionSelect,
            },
          },
          "video-conf-section-footer",
          _vm.sectionBindProps,
          false
        )
      ),
      _vm.isElectron
        ? _c("video-conf-source-select", { attrs: { share: _vm.share } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-input-hot-keys",
      style: _vm.wrapperStyleOnFocus,
      on: { focus: _vm.onFocus, blur: _vm.onBlur },
    },
    [
      !!_vm.placeholder && !!_vm.placeholder.length
        ? _c("div", { staticClass: "hotkeysplaceholder" }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ])
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.hotKeysStr,
            expression: "hotKeysStr",
          },
        ],
        ref: "hotKeysInput",
        style: _vm.inputStyleOnFocus,
        attrs: { type: "text", autofocus: "", placeholder: "" },
        domProps: { value: _vm.hotKeysStr },
        on: {
          keydown: function ($event) {
            return _vm.keysEntered($event)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.hotKeysStr = $event.target.value
          },
        },
      }),
      _c("div", { staticClass: "clear-sign" }, [
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.hotKeysStr.length,
              expression: "!!hotKeysStr.length",
            },
          ],
          staticClass: "fas fa-times",
          on: { click: _vm.onClear },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-input-text" }, [
    _c("div", { staticClass: "custom-input-text-content" }, [
      !!_vm.label
        ? _c(
            "div",
            {
              staticClass: "custom-input-text-placeholder",
              class: { up: !!_vm.input_text },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("i", {
        staticClass: "fal fa-chevron-down custom-input-text-arrow",
        class: { up: _vm.list_full },
        on: { click: _vm.toggleFullList },
      }),
    ]),
    _vm.type === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input_text,
              expression: "input_text",
            },
          ],
          staticClass: "input non-border-input",
          class: { up: !!_vm.input_text },
          attrs: {
            tabindex: _vm.tabindex ? _vm.tabindex : 0,
            autocomplete: _vm.autocomplete,
            type: "checkbox",
          },
          domProps: {
            checked: Array.isArray(_vm.input_text)
              ? _vm._i(_vm.input_text, null) > -1
              : _vm.input_text,
          },
          on: {
            keyup: [
              _vm.setFilteredList,
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("done")
              },
            ],
            blur: function ($event) {
              _vm.list_open = false
            },
            change: function ($event) {
              var $$a = _vm.input_text,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.input_text = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.input_text = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.input_text = $$c
              }
            },
          },
        })
      : _vm.type === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input_text,
              expression: "input_text",
            },
          ],
          staticClass: "input non-border-input",
          class: { up: !!_vm.input_text },
          attrs: {
            tabindex: _vm.tabindex ? _vm.tabindex : 0,
            autocomplete: _vm.autocomplete,
            type: "radio",
          },
          domProps: { checked: _vm._q(_vm.input_text, null) },
          on: {
            keyup: [
              _vm.setFilteredList,
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("done")
              },
            ],
            blur: function ($event) {
              _vm.list_open = false
            },
            change: function ($event) {
              _vm.input_text = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input_text,
              expression: "input_text",
            },
          ],
          staticClass: "input non-border-input",
          class: { up: !!_vm.input_text },
          attrs: {
            tabindex: _vm.tabindex ? _vm.tabindex : 0,
            autocomplete: _vm.autocomplete,
            type: _vm.type,
          },
          domProps: { value: _vm.input_text },
          on: {
            keyup: [
              _vm.setFilteredList,
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("done")
              },
            ],
            blur: function ($event) {
              _vm.list_open = false
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.input_text = $event.target.value
            },
          },
        }),
    !!_vm.servers.length
      ? _c(
          "ul",
          {
            staticClass: "custom-input-text-list",
            class: { h: !_vm.list_open },
          },
          _vm._l(_vm.servers, function (server, i) {
            return _c(
              "li",
              {
                staticClass: "server",
                on: {
                  click: function ($event) {
                    return _vm.setServer(i)
                  },
                },
              },
              [
                _c("icon-image", {
                  staticClass: "server-icon middle",
                  attrs: {
                    src:
                      "https://firelink.me/images/companies/logo/" +
                      server.logo +
                      ".jpeg",
                    text: server.name,
                  },
                }),
                _c("div", { staticClass: "server-descr" }, [
                  _c("span", { staticClass: "server-name" }, [
                    _vm._v(_vm._s(server.name)),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "server-address" }, [
                    _vm._v(_vm._s(server.address)),
                  ]),
                ]),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publication-video-search" }, [
    _c(
      "div",
      {
        staticClass: "preview-block",
        style: _vm.customStyle,
        on: {
          click: function ($event) {
            return _vm.openViewer(_vm.index)
          },
        },
      },
      [
        _vm.isPending
          ? _c("div", { staticClass: "pending" }, [
              _c(
                "div",
                {
                  staticClass: "upload-abort-btn message-btn",
                  on: { click: _vm.abortAjaxCall },
                },
                [
                  _c("progress-circle", {
                    attrs: {
                      load_progress: _vm.loadProgress,
                      radius: 18,
                      width: 48,
                      height: 48,
                    },
                  }),
                  _c("i", { staticClass: "fa fa-ban" }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._m(0),
        _c("div", { staticClass: "img-wrapper-video" }, [
          !_vm.src
            ? _c("img", {
                attrs: {
                  src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                },
              })
            : _c("img", {
                attrs: { draggable: "false", src: _vm.src, alt: "" },
              }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-btn-wrapper-video" }, [
      _c("div", { staticClass: "message-btn-video" }, [
        _c("i", { staticClass: "fa fa-play" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list found-chats" },
    _vm._l(_vm.items, function (chat, i) {
      return _c("chat-item", {
        key: i,
        attrs: {
          chat: chat,
          focused: _vm.focusOnItem === i,
          noSelection: true,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
'use strict';
import {
    GET_SIDE_TYPE,
    GET_MAIN_TYPE,
    GET_DOCUMENT_HIDDEN,
    GET_DOCUMENT_FOCUS,
    GET_SEARCH_OPENED,
    GET_INFO_NOTIFICATION,
    GET_SIDE_BAR_NOTIFICATION,
    GET_ACTIVITY_TYPE,
    GET_OPENED_MAIN_TYPE,
    GET_MAIN_TYPE_CAN_BACK,
} from '../gettersTypes'
import {
    MUT_SET_SIDE_TYPE,
    MUT_SET_ACTIVITY_TYPE,
    MUT_SET_DOCUMENT_HIDDEN,
    MUT_SET_DOCUMENT_FOCUS,
    MUT_SET_SEARCH_OPENED,
    MUT_INFO_NOTIFICATION,
    MUT_SET_SIDE_BAR_NOTIFICATION,
    MUT_DELETE_SIDE_BAR_NOTIFICATION,
    MUT_MAIN_TYPE_PUSH,
    MUT_MAIN_TYPE_REPLACE,
    MUT_MAIN_TYPE_BACK,
    MUT_MAIN_TYPE_CLEAR,
} from '../mutationsTypes'
import {
    ACT_MAIN_TYPE_BACK,
    ACT_MAIN_TYPE_CLEAR,
    ACT_PUSH_MAIN_TYPE,
    ACT_REPLACE_MAIN_TYPE,
} from '../actionsTypes'

export const SIDE_TYPES = {
    CONTACTS_LIST: "contacts-list",
    CHATS: "chats",
    CALLS_LIST: "calls-list",
    VOICEMAIL: "voicemail",
    MORE: "more",
    RADIO: "radio",
};

export const MAIN_TYPES = {
    CONTACT: "contact",
    CHANNEL: 'channel',
    CHAT: 'chat',
    NOTE: 'note',
    ACS: 'acs',
    ABOUT: 'about',
    PROFILE: 'profile',
    SESSIONS: 'sessions',
    SETTINGS: 'settings',
    SUPPORT: 'support',
    HOTKEYS: 'hotkeys',
    VIDEOCONFS: 'videoconfs',
    DECORATION: 'decoration',
    INTEGRATIONS: 'integrations',
    MEETINGCALENDAR: 'meetingcalendar'
}

export const CHAT_MAIN_TYPES = [
    MAIN_TYPES.CONTACT,
    MAIN_TYPES.CHAT,
    MAIN_TYPES.NOTE,
]

import { getIsFullCompactMode, setFullCompactVisibility } from '../../../ext/trackResizes';

export default  {
    namespaced: true,
    state: {
        sideType: SIDE_TYPES.CONTACTS_LIST,
        mainTypeHistory: [],
        mainType: null,
        activityType: null,
        documentHidden: false,
        documentFocus: true,
        searchOpened: false,
        infoNotification: null,
        sideBarNotification: {
            [SIDE_TYPES.CONTACTS_LIST]: '',
            [SIDE_TYPES.CHATS]: '',
            [SIDE_TYPES.CALLS_LIST]: ''
        },
        isFullCompactMode: false
    },
    getters: {
        [GET_OPENED_MAIN_TYPE]: state => state.mainTypeHistory[state.mainTypeHistory.length - 1],
        [GET_MAIN_TYPE_CAN_BACK]: state => state.mainTypeHistory.length > 1,
        [GET_SIDE_TYPE](state) {
            return state.sideType;
        },
        [GET_MAIN_TYPE](state, getters) {
            let opened = getters[GET_OPENED_MAIN_TYPE]
            return opened?.type
        },
        [GET_ACTIVITY_TYPE](state) {
            return state.activityType;
        },
        [GET_DOCUMENT_HIDDEN](state) {
            return state.documentHidden;
        },
        [GET_DOCUMENT_FOCUS](state) {
            return state.documentFocus;
        },
        [GET_SEARCH_OPENED](state) {
            return state.searchOpened;
        },
        [GET_INFO_NOTIFICATION](state) {
            return state.infoNotification;
        },
        [GET_SIDE_BAR_NOTIFICATION]: (state, getters) => (type) => {
            if (!type) type = getters[GET_SIDE_TYPE]
            return state.sideBarNotification[type]
        },
    },
    actions: {
        [ACT_PUSH_MAIN_TYPE]({ commit }, payload){
            commit(MUT_MAIN_TYPE_PUSH, payload)
        },
        [ACT_REPLACE_MAIN_TYPE]({ commit }, payload) {
            commit(MUT_MAIN_TYPE_REPLACE, payload)
            if (getIsFullCompactMode()) setFullCompactVisibility()
        },
        [ACT_MAIN_TYPE_BACK]({ dispatch, commit }) {
            commit(MUT_MAIN_TYPE_BACK)
        },
        [ACT_MAIN_TYPE_CLEAR]({ dispatch, commit }) {
            commit(MUT_MAIN_TYPE_CLEAR)
        },
    },
    mutations: {
        [MUT_MAIN_TYPE_PUSH](state, payload){
            state.mainTypeHistory.push(payload)
        },
        [MUT_MAIN_TYPE_REPLACE](state, payload){
            //if (state.mainTypeHistory.length) state.mainTypeHistory.splice(0, state.mainTypeHistory.length, payload)
            state.mainTypeHistory.splice(0, state.mainTypeHistory.length, payload)
        },
        [MUT_MAIN_TYPE_BACK](state){
            state.mainTypeHistory.pop()
        },
        [MUT_MAIN_TYPE_CLEAR](state){
            state.mainTypeHistory.splice(0, state.mainTypeHistory.length)
        },
        [MUT_SET_SIDE_TYPE]: function(state, value) {
            state.sideType = value;
        },
        [MUT_SET_ACTIVITY_TYPE]: function(state, value) {
            state.activityType = value;
        },
        [MUT_SET_DOCUMENT_HIDDEN]: function(state, value) {
            state.documentHidden = value;
        },
        [MUT_SET_DOCUMENT_FOCUS]: function(state, value) {
            state.documentFocus = value;
        },
        [MUT_SET_SEARCH_OPENED]: function(state, value) {
            state.searchOpened = value;
        },
        [MUT_INFO_NOTIFICATION]: function(state, value) {
            state.infoNotification = value;
        },
        [MUT_SET_SIDE_BAR_NOTIFICATION](state, value) {
            let { type, msg } = value
            state.sideBarNotification[type] = msg
        },
        [MUT_DELETE_SIDE_BAR_NOTIFICATION](state, value) {
            let { type, msg } = value
            let oldMsg = state.sideBarNotification[type]
            let newMsg = oldMsg.replace(msg, '')
            if (!newMsg.trim().length) newMsg = ''
            state.sideBarNotification[type] = newMsg
        },
    }
}

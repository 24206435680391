var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "contact-to-sent" },
            [
              _c("icon-image", {
                attrs: {
                  src: _vm.photoSrc,
                  remove: _vm.isPhotoRemovable,
                  rollBack: _vm.removedPhoto,
                },
                on: { remove: _vm.removePhoto, rollBack: _vm.rollbackPhoto },
              }),
              _c("div", { staticClass: "fio" }, [
                _vm._v(_vm._s(_vm.contact.fio)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "input-block",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                },
              },
            },
            _vm._l(_vm.fieldsArr, function (field, i) {
              return _c("custom-checkbox-round", {
                key: i,
                ref: "customCheckBox",
                refInFor: true,
                attrs: {
                  default: _vm.defChbValue,
                  label: field.value,
                  sublabel: _vm.getSubLabel(field.type),
                },
                on: {
                  "update:checkbox": function ($event) {
                    return _vm.onChbChecked(field, $event)
                  },
                },
              })
            }),
            1
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: { click: _vm.cancel },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("channel-comp.send") },
            on: { click: _vm.send },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-video-wrapper" }, [
    _vm.message.author
      ? _c("div", { staticClass: "forward" }, [
          _c("span", { staticClass: "fwd title" }, [
            _c("i", { staticClass: "fas fa-share" }),
            _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
          ]),
          _vm.fioById(_vm.message.author)
            ? _c("span", { staticClass: "fwd author" }, [
                _vm._v(
                  _vm._s(_vm.$t("chat.author")) +
                    ": " +
                    _vm._s(_vm.fioById(_vm.message.author))
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "chat-video-message" }, [
      _c("div", { staticClass: "blur-block" }, [
        _c("img", {
          attrs: { draggable: "false", src: _vm.preview_src, alt: "" },
        }),
      ]),
      _c("div", { staticClass: "preview-block" }, [
        _vm.isPending
          ? _c("div", { staticClass: "pending" }, [
              _c(
                "div",
                {
                  staticClass: "upload-abort-btn message-btn",
                  on: { click: _vm.abortAjaxCall },
                },
                [
                  _c("progress-circle", {
                    attrs: {
                      load_progress: _vm.loadProgress,
                      radius: 16,
                      width: 40,
                      height: 40,
                    },
                  }),
                  _c("i", { staticClass: "fa fa-ban" }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "img-wrapper", on: { click: _vm.doShowViewer } },
          [
            !_vm.preview_src
              ? _c("img", {
                  attrs: {
                    src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
                  },
                })
              : _c("img", {
                  attrs: { draggable: "false", src: _vm.preview_src, alt: "" },
                }),
            _vm._m(0),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-btn-wrapper" }, [
      _c("div", { staticClass: "message-btn" }, [
        _c("i", { staticClass: "fa fa-play" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
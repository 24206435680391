var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "div",
      { staticClass: "publication publication-invite" },
      [
        _c(
          "info-main-block",
          {
            attrs: {
              "icon-photo": _vm.photo,
              "icon-text": _vm.channel.name,
              "common-text": _vm.channel.name,
              "additional-text": _vm.channel.info,
              official: _vm.channel.verified,
            },
          },
          [
            _vm.channel.chType === "private"
              ? _c(
                  "div",
                  {
                    staticClass: "channel-type",
                    attrs: { slot: "after-common-text" },
                    slot: "after-common-text",
                  },
                  [
                    _c("span", { staticClass: "text color-red" }, [
                      _vm._v(_vm._s(_vm.$t("information.private"))),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "btns" }, [
          _c(
            "button",
            { staticClass: "btn", on: { click: _vm.onInviteBtnClick } },
            [_vm._v(_vm._s(_vm.$t("channel-invite-btn")))]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import IconImage from '../IconImage.vue';

import { GET_BOT_BY_ID } from '../../store/gettersTypes'
import { BOTS } from '../../store/modulesNames'

import ChatMessageMixin from './chat-message-mixin'
import {CONTACT_FIELD_TYPES} from '../../constants'

export default {
    name: 'chat-reply-message',
    components: {IconImage},
    mixins: [ChatMessageMixin],
    props: ['message', 'cid'],
    data() {
        return {
            classes: [],
        }
    },
    mounted() {
        if (['video', 'image'].includes(this.message.sub_type)) {
            this.classes.push('short')
        }
    },
    asyncComputed: {
        preview() {
            if (this.replymsg && this.withPreview(this.replymsg.msg.type)) {
                return this.getPreview(this.replymsg.msg)
            } else {
                return ''
            }
        },
        async replymsg() {
            let reply_msg = app.store.getters['chat/getMessageById'](this.message.replyId);
            if(!reply_msg) {
                reply_msg = await app.proto.getMessage({ cidType: this.message.cidType, cid: this.cid, id: this.message.replyId })
            }
            if (!reply_msg) {
                this.classes.push('deleted-message')
                return {
                    fio: '',
                    cid: '',
                    cidType: '',
                    dataType: 'text',
                    senderId: '',
                    msg: this.$t('deleted-message')
                }
            }

            let fio
            let bot = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](reply_msg.senderId);
            if(bot) fio = bot[CONTACT_FIELD_TYPES.BOTTITLE];
            else fio = app.store.getters['contacts/getMergedContactById'](reply_msg.senderId).fio;

            if(reply_msg.dataType === 'data') {
                if('pollId' in reply_msg.data) reply_msg.data.type = 'poll'
            }

            let result = {
                fio: fio,
                cid: reply_msg.cid,
                cidType: reply_msg.cidType,
                dataType: reply_msg.dataType,
                senderId: reply_msg.senderId,
                msg: reply_msg.data
            };
            return result;
        }
    },
    methods: {
        scrollingToElement: function(id) {
            if(id) {
                let element = document.querySelector('#msg' + id);
                element.scrollIntoView({behavior: "smooth", block: "center"});

                setTimeout(function() {
                    element.classList.add('scroll-msg');
                    element.style.opacity = 0.3;
                }, 500);
                setTimeout(function() {
                    element.style.opacity = 1;
                }, 800);
                setTimeout(function() {
                    element.style.opacity = 0.3;
                }, 1100);
                setTimeout(function() {
                    element.style.opacity = 1;
                }, 1400);
                setTimeout(function() {
                    element.classList.remove('scroll-msg');
                }, 1700);
            }
        },
        formattedMsgText(text) {
            const refsText = this.lookForRefs(text)
            const sanitized_text = refsText.replace(/</g,'&lt;').replace(/>/g,'&gt;')
            const out = this.wrapEmoji(sanitized_text)
            return out
        }
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drop-file",
      on: { drop: _vm.drop, dragleave: _vm.dragleave, dragover: _vm.dragover },
    },
    [_c("span", [_vm._v(_vm._s(_vm.$t("drop-to-send")))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
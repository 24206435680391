var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("my-groups")))]),
    _c(
      "ul",
      { staticClass: "list found-chats" },
      _vm._l(_vm.items, function (chat, i) {
        return _c("chat-item", {
          key: i,
          attrs: {
            chat: chat,
            focused: _vm.focusOnItem === i,
            noSelection: true,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "central acs" },
    [
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$t("mainPage.location")))]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("acs-status", {
                  attrs: {
                    contact_id: _vm.contact_id,
                    link: false,
                    loading: _vm.acsLoading,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("main-content-container", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$t("mainPage.journal")))]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _vm.hasRights
                  ? _c("acs-add-btn", {
                      on: { "record-saved": _vm.updateAcsInfo },
                    })
                  : _vm._e(),
                _c("acs-journal-list", {
                  ref: "journal_list",
                  attrs: { loading: _vm.acsLoading },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
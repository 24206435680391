var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pass-restore" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c("div", { staticClass: "component-section" }, [
      _vm.sended
        ? _c("div", { staticClass: "sended" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("loginPage.mail-sent")))]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { tabindex: "3" },
                on: {
                  click: _vm.$parent.doBack,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$parent.doBack($event)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("loginPage.ok")))]
            ),
          ])
        : _c(
            "form",
            {
              staticClass: "input-block",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.doPassRestore($event)
                },
              },
            },
            [
              _c("custom-input-text", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                ref: "input_mail",
                attrs: {
                  input_text: _vm.login,
                  label: _vm.$t("loginPage.email"),
                  tabindex: 1,
                },
                on: {
                  "update:input_text": function ($event) {
                    _vm.login = $event
                  },
                },
              }),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    id: "pass-restore-btn",
                    disabled: !_vm.login || _vm.sending,
                    tabindex: "2",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("loginPage.send-mail")))]
              ),
            ],
            1
          ),
    ]),
    _c("div", { staticClass: "component-footer" }, [
      _c("div", { staticClass: "description text-center" }, [
        _vm._v(_vm._s(_vm.$t("loginPage.forgot-pass-desc"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-header-info",
      attrs: { id: "contact-main-header-info" },
    },
    [
      _c("div", { staticClass: "header-text" }, [
        _c(
          "div",
          {
            staticClass: "header-user-block",
            attrs: { title: _vm.getTitle },
            on: {
              click: _vm.doToggleOpenInfo,
              mouseover: function ($event) {
                return _vm.headerMouseOver($event)
              },
            },
          },
          [
            _c("icon-image", {
              attrs: { src: _vm.contact.photo, text: _vm.contact.fio },
            }),
            _c("div", { staticClass: "contact-text-wrapper header-main" }, [
              _c(
                "div",
                {
                  staticClass: "contact-name-block",
                  style: _vm.contact.official
                    ? "display: flex; align-items: center;"
                    : "",
                },
                [
                  _c("div", { staticClass: "fio" }, [
                    _c("span", { staticClass: "fiospan" }, [
                      _vm._v(_vm._s(_vm.contact.fio)),
                    ]),
                  ]),
                  _vm.contact.official
                    ? _c("div", { staticClass: "official" }, [
                        _c("i", { staticClass: "far fa-check" }),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.isViewProfileAllowed
                ? _c(
                    "div",
                    { staticClass: "contact-status-block" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.typing,
                              expression: "!typing",
                            },
                          ],
                          staticClass: "contact-status-text sub-text",
                        },
                        [_vm._v(_vm._s(_vm.status_text))]
                      ),
                      _c("typing-status", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.typing,
                            expression: "typing",
                          },
                        ],
                        attrs: { cid: _vm.cid, cidType: _vm.cidType },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "header-btns contact-buttons-block" }, [
        _c("div", {
          staticClass: "circle-animation header-chat-search",
          attrs: { title: _vm.$t("search-in-chat") },
          on: {
            click: function ($event) {
              return _vm.openSearchChat()
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "circle-animation",
            attrs: {
              title: !_vm.isCallBtnEnabled
                ? _vm.$t("calls-prohibited")
                : _vm.callIconTitle,
            },
            on: {
              click: function ($event) {
                _vm.isCallBtnEnabled ? _vm.doCall("audio") : ""
              },
            },
          },
          [
            _c("i", {
              staticClass: "audio-call fa fa-phone",
              class: { disabled: !_vm.isCallBtnEnabled },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "circle-animation",
            attrs: { title: _vm.$t("info") },
            on: {
              click: function ($event) {
                _vm.isViewProfileAllowed ? _vm.doToggleOpenInfo() : ""
              },
            },
          },
          [
            _c("i", {
              staticClass: "fal fa-window-maximize fa-rotate-90",
              class: { disabled: !_vm.isViewProfileAllowed },
            }),
          ]
        ),
        !_vm.contact.temp
          ? _c(
              "div",
              {
                staticClass: "circle-animation",
                attrs: { title: _vm.$t("mainPage.menu") },
                on: {
                  click: function ($event) {
                    return _vm.doMenu($event, "bottom-right")
                  },
                },
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } }),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("my-channels"))),
    ]),
    _c(
      "ul",
      { staticClass: "list found-channels" },
      _vm._l(_vm.items, function (channel, i) {
        return _c(
          "li",
          [
            _c("channel-item", {
              attrs: {
                channel: channel,
                type: "search",
                focused: _vm.focusOnItem === i,
                noSelection: true,
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.allIsReady,
            expression: "!allIsReady",
          },
        ],
        staticClass: "settings-placeholder",
      },
      [_vm._m(0)]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.allIsReady,
            expression: "allIsReady",
          },
        ],
        staticClass: "central settings",
      },
      [
        _vm.isElectron
          ? _c("general-settings", {
              on: { created: _vm.compCreated, ready: _vm.setReady },
            })
          : _vm._e(),
        _c("chat-settings"),
        _c("notification-settings"),
        _c("theme"),
        _c("language-settings"),
        _vm.assistants.length ? _c("select-assistant") : _vm._e(),
        _c("hot-keys"),
        _vm.showIntegrations ? _c("integrations") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-spinner" }, [
      _c("i", { staticClass: "fal fa-spinner fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
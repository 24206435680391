var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "circular-chart",
      attrs: { viewbox: "0 0 " + _vm.width + " " + _vm.height },
    },
    [
      _c("circle", {
        staticClass: "progress__value",
        style: _vm.style,
        attrs: {
          cx: _vm.width / 2,
          cy: _vm.height / 2,
          r: _vm.radius,
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c(
        "div",
        { staticClass: "selected-wrapper" },
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { load_id: _vm.load_id, id: _vm.id },
            on: { clear: _vm.clear, loaded: _vm.fireLoaded },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
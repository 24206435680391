var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conf-btn", class: _vm.classes, style: _vm.cssVars },
    [
      _vm.chevron
        ? _c(
            "div",
            {
              staticClass: "chevron",
              on: {
                click: function ($event) {
                  return _vm.$emit("chevron", $event)
                },
              },
            },
            [_c("i", { staticClass: "fal fa-chevron-up" })]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "btn-wrapper",
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "image-wrapper" }, [
            _vm.imageType === "icon"
              ? _c("i", { class: _vm.imageClasses })
              : _vm._e(),
          ]),
          _vm.subTitle
            ? _c("div", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(_vm.subTitle)),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "side-list-item chat-list-item",
      class: { selected: _vm.isSelected, focused: _vm.focused },
      attrs: { title: _vm.channelTitle },
      on: {
        click: function ($event) {
          return _vm.doSelectChannel(_vm.channel.chId)
        },
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.doMenu($event)
        },
        dragenter: _vm.channelItemDragenter,
        dragover: _vm.channelItemDragover,
        dragleave: _vm.channelItemDragleave,
      },
    },
    [
      _c("icon-image", { attrs: { src: _vm.photo, text: _vm.channel.name } }),
      _c("div", { staticClass: "text-block" }, [
        _c("div", { staticClass: "name" }, [
          _c("i", { staticClass: "item-icon far fa-bullhorn" }),
          _c(
            "span",
            {
              staticClass: "check-trim",
              on: {
                mouseover: function ($event) {
                  return _vm.mouseOver($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.channel.name))]
          ),
          _vm.verifed
            ? _c("div", { staticClass: "official" }, [
                _c("i", { staticClass: "far fa-check" }),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.lookForRefs(_vm.channel.info) ||
                  _vm.lookForRefs(_vm.channel.text)
              )
            ),
          ]),
        ]),
      ]),
      _c("chat-item-right-block", { attrs: { item: _vm.channel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
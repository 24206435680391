import { render, staticRenderFns } from "./ReactionsPicker.vue?vue&type=template&id=58098a10&scoped=true&lang=pug&"
import script from "./ReactionsPicker.vue?vue&type=script&lang=js&"
export * from "./ReactionsPicker.vue?vue&type=script&lang=js&"
import style0 from "./ReactionsPicker.vue?vue&type=style&index=0&id=58098a10&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58098a10",
  null
  
)

export default component.exports
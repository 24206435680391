var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selected-wrapper" }, [
    _c(
      "div",
      { staticClass: "selected" },
      _vm._l(_vm.selected, function (item, index) {
        return _c(
          "div",
          { staticClass: "selected-item" },
          [
            _c("icon-image", {
              attrs: { src: item.iconPhoto, text: item.iconText, remove: true },
              on: {
                remove: function ($event) {
                  return _vm.$emit("remove", index)
                },
              },
            }),
            _c("span", [_vm._v(_vm._s(item.text))]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
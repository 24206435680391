var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "btns info-section" }, [
    _c("div", { staticClass: "btns-list item-cont" }, [
      _c(
        "div",
        { staticClass: "btn-list-item list-item", on: { click: _vm.click } },
        [
          _c("i", { staticClass: "btn-icon", class: _vm.btnClass }),
          _c("span", { staticClass: "btn-text" }, [
            _vm._v(_vm._s(_vm.btnText)),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ReactionsPanel.vue?vue&type=template&id=5f409c32&scoped=true&lang=pug&"
import script from "./ReactionsPanel.vue?vue&type=script&lang=js&"
export * from "./ReactionsPanel.vue?vue&type=script&lang=js&"
import style0 from "./ReactionsPanel.vue?vue&type=style&index=0&id=5f409c32&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f409c32",
  null
  
)

export default component.exports
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conf-contain" },
    [
      _c(
        "transition",
        { attrs: { name: "show" } },
        [
          _c(
            _vm.component,
            _vm._b(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPhone,
                    expression: "showPhone",
                  },
                ],
                tag: "div",
                staticClass: "component",
              },
              "div",
              _vm.call,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import ipc from '../../../electron/ipc'
import {
    GET_PHONE_SHOW,
    GET_MY_FULL_NAME,
    GET_PHONE_ACTIVE_CALL,
    GET_PHONE_CALL,
    GET_PHONE_CALL_WORKER,
    GET_PHONE_RTC_OPTIONS,
    GET_ACTIVE_WEBCAMERA,
    GET_ACTIVE_MICROPHONE,
    GET_PHONE_SIP_PAIR,
    GET_PHONE_CONF_CALL,
    GET_PHONE_CALL_SERVER_ID,
    GET_MERGED_CONTACT_BY_ID,
    GET_PHONE_CALL_MOS,
    GET_CLIENT_VERSION,
} from '../gettersTypes';
import {
    ACT_PHONE_CALL,
    ACT_EVENT_PHONE,
    ACT_PHONE_COMMANDS,
    ACT_PHONE_CHANGE_STATE,
    ACT_PHONE_HIDE,
    ACT_PHONE_HOLD,
    ACT_SET_ANSWERED_CALL_STATUS,
    ACT_TERMINATE_CALL,
    ACT_PHONE_RESIZE,
    // ACT_UPDATE_ANSWERED_CALL_ID,
    ACT_PHONE_CALL_CONTINUE,
    ACT_PHONE_CREATE_CALL_WORKER,
    ACT_GET_RTC_CALL_AVAILABILITY,
    ACT_PHONE_SELECT,
    ACT_PHONE_TERMINATE_CALL,
    ACT_TOGGLE_PHONE_MINIMIZE,
    ACT_PHONE_UPDATE_CALL_CON_STATUS,
    ACT_PHONE_RTC_CALL,
    ACT_PHONE_RTC_CALL_API,
    ACT_PHONE_GET_ICE_SERVERS,
    ACT_PHONE_RTC_CALL_RINGING_API,
    ACT_PHONE_RTC_CALL_TERMINATION_API,
    ACT_PHONE_RTC_CALL_OPTS_CHANGED_API,
    ACT_PHONE_RTC_CALL_ANSWER_API,
    ACT_PHONE_RTC_CALL_DTMF_API,
    ACT_PHONE_RTC_CALL_TRANSIT,
    ACT_PHONE_RTC_CALL_TRANSIT_API,
    ACT_PHONE_RTC_CALL_CONF_LIST_API,
    ACT_PHONE_RTC_CALL_CONF_LIST_UPDATE,
    ACT_PHONE_RTC_CALL_CONFERENCE,
    ACT_PHONE_RTC_CALL_CONFERENCE_API,
    ACT_PHONE_SET_CALLS_ON_HOLD,
    ACT_SET_CALL_PARTICIPANTS,
    ACT_PHONE_RTC_CALL_DTMF,
    ACT_PHONE_RTC_SET_CALL_STATS,
    ACT_PHONE_RTC_CALL_SEND_REPORT_API,
    ACT_PHONE_RTC_SEND_CALL_PROCESSING,
    ACT_PHONE_RTC_CALL_HANDLE_API_CALLS_EVENT,
} from '../actionsTypes'
import {
    MUT_PHONE_UPDATE_CALL,
    MUT_PHONE_HIDE,
    SAVE_SCREEN_SIZE,
    MUT_PHONE_ADD_CALL,
    MUT_PHONE_DELETE_CALL,
    MUT_PHONE_CALL_SELECT,
    MUT_PHONE_SET_CALL_DELETE_TIMEOUT,
    MUT_PHONE_CONF_LIST_UPDATE,
    MUT_PHONE_SET_CALL_TRANSITED,
    MUT_PHONE_SET_CALL_MOS,
} from '../mutationsTypes';
import {
    CONTACTS,
    PHONE_CNTL,
    USERDATA,
    CLIENTDATA,
} from '../modulesNames';
import {rtcCallStates, rtcCallTypes, EVENTS} from '../../callWorker';
import CallWorker from '../../callWorker';

import {ANSWERED_CALL_STATUSES, PHONE_TYPES} from '../../constants'
import {console} from "vuedraggable/src/util/helper"

const CALL_PROPS = {
    CALL_ID: 'callId',
    SERVER_ID: 'serverId',
    TYPE: 'type',
    CONNECTION_STATUS: 'connectionStatus',
    CID: 'cid',
    NUMBER: 'number',
    PARTICIPANTS: 'participants',
    TRANSITED: 'transited',
    STATE: 'state',
    IN_CALL: 'inCall',
    MUTE: 'mute',
    HOLD: 'hold',
    WAS_CONNECTED: 'wasConnected',
    DISCONNECTED_LOCAL: 'disconnectedLocal',
}

export const CALL_CONNECTION_STATUSES = {
    CREATED: 0,
    CONNECTING: 1,
    CONNECTED: 2,
    DISCONNECTED: 3,
    ERROR: 4,
}

const DEFAULT_CALL_DATA = Object.freeze({
    [CALL_PROPS.CALL_ID]: null,
    [CALL_PROPS.SERVER_ID]: null,
    [CALL_PROPS.TYPE]: null,
    [CALL_PROPS.CONNECTION_STATUS]: CALL_CONNECTION_STATUSES.CREATED,
    [CALL_PROPS.CID]: null,
    [CALL_PROPS.NUMBER]: null,
    [CALL_PROPS.STATE]: null,
    [CALL_PROPS.IN_CALL]: false,
    [CALL_PROPS.MUTE]: false,
    [CALL_PROPS.HOLD]: false,
    [CALL_PROPS.WAS_CONNECTED]: false,
    [CALL_PROPS.DISCONNECTED_LOCAL]: false,
    [CALL_PROPS.PARTICIPANTS]: [],
    [CALL_PROPS.TRANSITED]: false,
})

let callWorkers = {}

const brandsData = navigator && navigator.userAgentData && navigator.userAgentData.brands && navigator.userAgentData.brands[1]
const brand = (brandsData && brandsData.brand) || ''
const version = (brandsData && brandsData.version) || ''
const reportStatus = {
    calls: [],
        carrierCountryCode: '',
        carrierName: '',
        localizedModel: '', //testModelName,
        model: '',
        network: '',
        softBuild: 0,
        softVersion: '', //clientVersion,
        systemName: brand,
        systemVersion: version
}

// state
const state = {
    show: false,
    calls: {},
    callsPriority: [],
    callsMOS: [],
    stun: null,
    screenSize: {
        width: 300,
        height: 500
    }
}

// getters
const getters = {
    getStun(state) {
        return state.stun
    },
    [GET_PHONE_SHOW] (state, getters) {
        let call = getters[GET_PHONE_ACTIVE_CALL]
        let show = true
        if (!call) {
            show = false
            call = DEFAULT_CALL_DATA
        }
        return {
            show,
            stun: state.stun,
            screenSize: state.screenSize,
            ...call,
        }
    },
    [GET_PHONE_ACTIVE_CALL](state) {
        return state.callsPriority[state.callsPriority.length - 1]
    },
    [GET_PHONE_CALL]: (state) => (callId) => {
        return getCall(state, callId)
    },
    [GET_PHONE_CALL_SERVER_ID]: (state, getters) => (callId) => {
        const call = getters[GET_PHONE_CALL](callId)
        return call && call[CALL_PROPS.SERVER_ID]
    },
    [GET_PHONE_CALL_WORKER]: (state) => (callId) => {
        return getCallWorker(state, callId)
    },
    getScreenSize (state) {
        return state.screenSize
    },
    [GET_PHONE_CALL_MOS]: (state, getters) => (callId) => {
        const id = getters[GET_PHONE_CALL_SERVER_ID](callId)
        const found = state.callsMOS.find(obj => obj.callId === id)
        let mos = found && found.MOS || ''
        if (found && found.remoteMOS && found.remoteMOS < mos) mos = found.remoteMOS 
        return +mos
    },
    [GET_PHONE_RTC_OPTIONS]: (state, getters, rootState, rootGetters) => (video = false) => {
        let options = {audio: true, video}
        if (options.video) {
            const activeCamera = rootGetters[`${USERDATA}/${GET_ACTIVE_WEBCAMERA}`]
            if (activeCamera && activeCamera.deviceId && activeCamera.deviceId !== 'default')
                options.video = { deviceId: { exact: activeCamera.deviceId } }
        }
        const activeMicrophone = rootGetters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`]
        if (activeMicrophone && activeMicrophone.deviceId && activeMicrophone.deviceId !== 'default')
            options.audio = {  deviceId: { exact: activeMicrophone.deviceId } }
        return options
    },
    [GET_PHONE_SIP_PAIR]: (state, getters) => {
        let currentCall = getters[GET_PHONE_ACTIVE_CALL]
        if (currentCall[CALL_PROPS.NUMBER]) {
            const secondCall = state.callsPriority.slice().reverse().find(call => call !== currentCall && call[CALL_PROPS.NUMBER])
            if (secondCall) return [secondCall[CALL_PROPS.SERVER_ID], currentCall[CALL_PROPS.SERVER_ID]]
        }
        return null
    },
    [GET_PHONE_CONF_CALL]: (state) => {
        return state.callsPriority.slice().reverse().find(call => call[CALL_PROPS.PARTICIPANTS].length)
    },
};

// actions
const actions = {
    // ------------------ Abstract actions --------------------------
    [ACT_PHONE_GET_ICE_SERVERS]() {
    },
    [ACT_PHONE_RTC_CALL_API]() {
    },
    [ACT_PHONE_RTC_CALL_ANSWER_API]() {
    },
    [ACT_PHONE_RTC_CALL_RINGING_API]() {
    },
    [ACT_PHONE_RTC_CALL_TERMINATION_API]() {
    },
    [ACT_PHONE_RTC_CALL_OPTS_CHANGED_API]() {
    },
    [ACT_PHONE_RTC_CALL_DTMF_API]() {
    },
    [ACT_PHONE_RTC_CALL_TRANSIT_API]() {
    },
    [ACT_PHONE_RTC_CALL_CONFERENCE_API]() {
    },
    [ACT_PHONE_RTC_CALL_CONF_LIST_API]() {
    },
    [ACT_PHONE_RTC_CALL_SEND_REPORT_API]() {
    },
    [ACT_PHONE_RTC_SEND_CALL_PROCESSING]() {
    },
    [ACT_PHONE_RTC_CALL_HANDLE_API_CALLS_EVENT]() {
    },
    // --------------------------------------------------------------
    setStun({state, commit, rootGetters}) { //@todo
        const user_info = rootGetters[`${USERDATA}/userInfo`]
        const stun = user_info.extParams.stunServer
        commit('setStun', stun)
    },
    async [ACT_PHONE_CREATE_CALL_WORKER]({state, commit, dispatch, getters, rootGetters}, data) {
        const plugText = rootGetters[`${CONTACTS}/${GET_MY_FULL_NAME}`]
        let {callId, cid, number, video, isIncomming = false, options } = data
        if (!video && options && options.video) video = options.video
        let type = number ? rtcCallTypes.rtcCallTypeSip : (video ? rtcCallTypes.rtcCallTypeVideo : rtcCallTypes.rtcCallTypeAudio)
        let iceServers = await dispatch(ACT_PHONE_GET_ICE_SERVERS)
        let callWorker = new CallWorker(this, callId, plugText, iceServers)

        callWorker.on(EVENTS.CALL_ID_CHANGED, ({oldId, newId}) => {
            if (newId) commit(MUT_PHONE_UPDATE_CALL, {[CALL_PROPS.CALL_ID]: oldId, callPayload: {[CALL_PROPS.SERVER_ID]: newId}})
        })

        callWorker.on(EVENTS.STATE_CHANGED, (payload) => {
            dispatch(ACT_PHONE_CHANGE_STATE, payload)
        })

        callWorker.on(EVENTS.RTC_CALL, async (payload) => {
            dispatch(ACT_PHONE_RTC_CALL, payload)
        })

        callWorker.on(EVENTS.RTC_CALL_ANSWER, async (payload) => {
            dispatch(ACT_PHONE_RTC_CALL_ANSWER_API, {...payload, ...{candidates: prepareCandidates(payload.candidates)}})
        })

        callWorker.on(EVENTS.RTC_CALL_RINGING, async (payload) => {
            dispatch(ACT_PHONE_RTC_CALL_RINGING_API, payload)
        })

        callWorker.on(EVENTS.RTC_CALL_TERMINATION, async (payload) => {
            dispatch(ACT_PHONE_RTC_CALL_TERMINATION_API, payload)
            if (reportStatus.calls.length && payload.callId >= 0) {
                const existedCallIndex = reportStatus.calls.findIndex(call => call.callId === callId)
                if (existedCallIndex) reportStatus.calls.splice(existedCallIndex)
            } 
        })

        let contact
        if (cid) contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid)
        const callIncoming = isIncomming ? 1 : 0
        const callAbonentCid = cid
        const callAbonent = contact ? contact.fio : ''

        callWorker.on(EVENTS.CALL_STATS, async (stats) => {
            dispatch(ACT_PHONE_RTC_SET_CALL_STATS, {
                callId,
                stats: {
                    callIncoming,
                    callAbonentCid,
                    callAbonent,
                    callNumber: number,
                    ...stats
                },
            })
        })

        callWorker.on(EVENTS.RTC_CALL_OPTIONS_CHANGE, async (payload) => {
            dispatch(ACT_PHONE_RTC_CALL_OPTS_CHANGED_API, payload)
        })
        
        let callPayload = {
            [CALL_PROPS.CALL_ID]: callId,
            [CALL_PROPS.TYPE]: type,
            [CALL_PROPS.CID]: cid,
            [CALL_PROPS.NUMBER]: number,
            [CALL_PROPS.IN_CALL]: isIncomming,
        }
        
        if (isIncomming) callPayload[CALL_PROPS.SERVER_ID] = callId

        commit(MUT_PHONE_ADD_CALL, {callId: data.callId, callWorker, callPayload})
        dispatch(ACT_PHONE_SELECT, {callId: data.callId, active: true })
        return callWorker
    },
    async [ACT_PHONE_CALL]({state, commit, getters, dispatch, rootGetters }, data) {
        let params = {}
        params.show = true;
        let call = state.callsPriority.find(item => item.cid === data.cid)
        let callWorker = call && getCallWorker(state, call[CALL_PROPS.CALL_ID])
        if (!callWorker) {
            callWorker = await dispatch(ACT_PHONE_CREATE_CALL_WORKER, data)
            dispatch(ACT_PHONE_RESIZE, { width: 344, height: 544 })
            let { availability } = await dispatch(`${PHONE_CNTL}/${ACT_GET_RTC_CALL_AVAILABILITY}`, data, {root: true})
            let options = getters[GET_PHONE_RTC_OPTIONS](data.video)
            if (availability) 
            {
                try {
                    await callWorker.call(data.cid, data.number, options)
                } catch(error) {
                    console.log("!! error", error)
                    const callId = data.callId
                    dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_CALL}`, { type: PHONE_TYPES.PHONE, id: callId }, { root: true })
                    commit(MUT_PHONE_DELETE_CALL, callId)
                }
            }
            else callWorker.setCallFreeAwaiting(data.cid, data.number, options)
        }
    },
    [ACT_PHONE_CALL_CONTINUE]({state}, {id}) {
        let worker = getCallWorker(state, id)
        if (worker) worker.call()
    },
    [ACT_PHONE_HOLD]: ({ dispatch, commit, getters }, {callId, hold}) => {
        const call = getters[GET_PHONE_CALL](callId)
        const callWorker = getCallWorker(state, callId)
        if (!callWorker) return
        callWorker.pause(hold)
        commit(MUT_PHONE_UPDATE_CALL, {callId, callPayload: {[CALL_PROPS.HOLD]: hold}})
        dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
            type: PHONE_TYPES.PHONE,
            id: call[CALL_PROPS.CALL_ID],
            status: hold ? ANSWERED_CALL_STATUSES.HOLD : ANSWERED_CALL_STATUSES.TALK,
        }, {root: true})
    },
    [ACT_PHONE_SELECT]: ({ state, dispatch, getters, commit }, {callId, active}) => {
        const call = getters[GET_PHONE_CALL](callId)
        dispatch(ACT_PHONE_SET_CALLS_ON_HOLD, callId)
        dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
            type: PHONE_TYPES.PHONE,
            id: call[CALL_PROPS.CALL_ID],
            active: true,
        }, {root: true})
        commit(MUT_PHONE_CALL_SELECT, callId)
        if (active && call[CALL_PROPS.HOLD]) {
            dispatch(ACT_PHONE_HOLD, {callId, hold: false})
            dispatch(ACT_PHONE_COMMANDS, { params: { callId }, command: 'updateStreams' })
        }
        if (call && call[CALL_PROPS.TYPE] === rtcCallTypes.rtcCallTypeVideo) dispatch(ACT_PHONE_RESIZE, { width: state.screenSize.width + 44, height: state.screenSize.height + 44 })
        else dispatch(ACT_PHONE_RESIZE, { width: 344, height: 544 })
        dispatch(`${PHONE_CNTL}/${ACT_TOGGLE_PHONE_MINIMIZE}`, {minimize : false}, {root: true})
    },
    [ACT_PHONE_SET_CALLS_ON_HOLD]({ state, dispatch }, callIdException) {
        state.callsPriority.forEach(call => {
            if (!callIdException || (call[CALL_PROPS.CALL_ID] !== callIdException && !call[CALL_PROPS.HOLD]))
                dispatch(ACT_PHONE_HOLD, {callId: call[CALL_PROPS.CALL_ID], hold: true})
        })
    },
    [ACT_PHONE_TERMINATE_CALL]: ({state, commit, dispatch, getters}, {callId, local, reason}) => {
        const callWorker = getCallWorker(state, callId)
        const call = getters[GET_PHONE_CALL](callId)
        let deleteTimeout = 0
        if (callWorker) {
            const disconnected = call && call[CALL_PROPS.CONNECTION_STATUS] >= CALL_CONNECTION_STATUSES.DISCONNECTED
            const disconnectedLocal = call && call[CALL_PROPS.DISCONNECTED_LOCAL]

            if (local && !disconnectedLocal) {
                callWorker.callTerminate()
            } else if (!disconnected) {
                callWorker.callTerminated(callId, reason)
            } else {
                return
            }

            dispatch(`${PHONE_CNTL}/${ACT_TOGGLE_PHONE_MINIMIZE}`, {minimize : false}, {root: true})
        }
        dispatch(ACT_PHONE_UPDATE_CALL_CON_STATUS, {callId, status: CALL_CONNECTION_STATUSES.DISCONNECTED, discLocal: local})

        const delCb = () => {
            dispatch(`${PHONE_CNTL}/${ACT_TERMINATE_CALL}`, { type: PHONE_TYPES.PHONE, id: call[CALL_PROPS.CALL_ID] }, { root: true })
            commit(MUT_PHONE_DELETE_CALL, callId)
        }

        if (reason === 'conference-redirect') {
            let confCall = getters[GET_PHONE_CONF_CALL]
            if (confCall) dispatch(ACT_PHONE_SELECT, {callId: confCall[CALL_PROPS.CALL_ID], active: true})
            else setTimeout(() => {
                confCall = getters[GET_PHONE_CONF_CALL]
                if (confCall) dispatch(ACT_PHONE_SELECT, {callId: confCall[CALL_PROPS.CALL_ID], active: true})
            }, 100)
        } else if (!call || !call[CALL_PROPS.TRANSITED]) {
            deleteTimeout = local ? 1500 : 3000
        }
        if (deleteTimeout) {
            const timeOut = setTimeout(delCb, deleteTimeout)
            commit(MUT_PHONE_SET_CALL_DELETE_TIMEOUT, {callId, timeOut})
        } else {
            delCb()
        }
    },
    [ACT_PHONE_RESIZE]: (obj, {width, height}) => {
        ipc.send('set-phone-size', {width, height})
    },
    [ACT_PHONE_COMMANDS]({ state, getters, commit, dispatch }, data) {
        console.log('data', data)
        if (!data) return
        let callId = data.params.callId
        let call = getCall(state, callId)
        let callWorker = getCallWorker(state, callId)
        let callPayload = {}

        switch (data.command) {
            case 'terminate':
                dispatch(ACT_PHONE_TERMINATE_CALL, {callId, local: true})
                break;
            case 'answer':
                let video = call[CALL_PROPS.TYPE] === rtcCallTypes.rtcCallTypeVideo
                let options = getters[GET_PHONE_RTC_OPTIONS](video)
                callWorker.answer(options)
                break;
            case 'mediaWorkerMute':
                callPayload[CALL_PROPS.MUTE] = callWorker.mediaWorkerMute()
                break;
            case 'mediaWorkerPause':
                dispatch(ACT_PHONE_HOLD, {callId, hold: data.pause})
                break;
            case 'updateStreams':
                if (callWorker) callWorker.updateStreams();
                break;
            default:
                break;
        }

        commit(MUT_PHONE_UPDATE_CALL, {callId, callPayload})
    },
    async [ACT_EVENT_PHONE]({ state, commit, dispatch, getters, rootGetters}, params) {
        let callWorker = getCallWorker(state, params.data.callId)
        if (!callWorker && params.eventName !== 'rtc-call-event') return

        let data = params.data
        switch (params.eventName) {
            case 'rtc-call-event':
                if (!callWorker) callWorker = await dispatch(ACT_PHONE_CREATE_CALL_WORKER, params.data)
                // уже создали такой вызов
                else return
                let options = getters[GET_PHONE_RTC_OPTIONS](data.options.video)
                callWorker.called(data.cid, data.callId, options, data.number, data.sdp, data.candidates)
                dispatch(ACT_PHONE_COMMANDS, { command: 'answer', params: { callId: data.callId } })
                dispatch(ACT_PHONE_RESIZE, { width: 344, height: 544 })
                break;
            case 'rtc-call-ringing-event':
                callWorker.callRinged(data.callId);
                break;
            case 'rtc-call-answer-event':
                callWorker.callAnswered(data.callId, data.options, data.sdp, data.candidates)
                break;
            case 'rtc-call-termination-event':
                dispatch(ACT_PHONE_TERMINATE_CALL, {callId: data.callId, reason: data.reason})
                break;
            case 'rtc-call-processing-event':
                //console.log("🚀 ~ file: phone.js:490 ~ rtc-call-processing-event data", data)
                if (data.msg === 'add-candidates') {
                    data.data.forEach(item => callWorker.addRemoteIceCandidate(item))
                }
                if (data.msg === 'stats') {
                    const callId = data.callId || ''
                    const remoteMOS = data.data && data.data.mos.toFixed(2)
                    commit(MUT_PHONE_SET_CALL_MOS, { callId, remoteMOS })
                }
                break;
            case 'rtc-call-options-event': //@todo в дате не только холд
                callWorker.callHold(data.options.hold);
                break;
            case 'rtc-call-conference-event': {
                dispatch(ACT_PHONE_RTC_CALL_CONF_LIST_UPDATE, data)
                break
            }
            default:
                break;
        }
    },
    [ACT_PHONE_CHANGE_STATE]({state, commit, getters, dispatch, rootGetters}, data) {
        let callId = data.callId
        let call = getters[GET_PHONE_CALL](callId)
        let callPayload = {}
        let status
        if (!call) return
        switch (data.state) {
            case rtcCallStates.rtcCallStateConnected:
                if (call[CALL_PROPS.CONNECTION_STATUS] > CALL_CONNECTION_STATUSES.CONNECTING) return
                else status = CALL_CONNECTION_STATUSES.CONNECTED
                break
            case rtcCallStates.rtcCallStateConnecting:
                if (call[CALL_PROPS.CONNECTION_STATUS] === CALL_CONNECTION_STATUSES.CONNECTING) return
                else status = CALL_CONNECTION_STATUSES.CONNECTING
                break
        }

        if (status) dispatch(ACT_PHONE_UPDATE_CALL_CON_STATUS, { callId, status })

        if(CALL_PROPS.STATE in data) callPayload[CALL_PROPS.STATE] = data[CALL_PROPS.STATE];
        commit(MUT_PHONE_UPDATE_CALL, { callId, callPayload });
    },
    [ACT_PHONE_HIDE]({ commit }) {
        commit(MUT_PHONE_HIDE);
    },
    [ACT_PHONE_UPDATE_CALL_CON_STATUS]({state, commit, getters, dispatch}, {callId, status, discLocal = false}) {
        let callPayload = { [CALL_PROPS.CONNECTION_STATUS]: status }
        if (status === CALL_CONNECTION_STATUSES.DISCONNECTED) callPayload[CALL_PROPS.DISCONNECTED_LOCAL] = discLocal
        else if (status === CALL_CONNECTION_STATUSES.CONNECTED)  {
            const call = getters[GET_PHONE_CALL](callId)
            callPayload[CALL_PROPS.WAS_CONNECTED] = true
            dispatch(`${PHONE_CNTL}/${ACT_SET_ANSWERED_CALL_STATUS}`, {
                type: PHONE_TYPES.PHONE,
                id: call[CALL_PROPS.CALL_ID],
                status: ANSWERED_CALL_STATUSES.TALK
            }, { root: true })
        }
        commit(MUT_PHONE_UPDATE_CALL, { callId, callPayload });
    },
    async [ACT_PHONE_RTC_CALL]({getters, dispatch}, payload) {
        let callId = payload.rtcCallId
        let call = getters[GET_PHONE_CALL](callId)
        if(call && call[CALL_PROPS.CONNECTION_STATUS] < CALL_CONNECTION_STATUSES.DISCONNECTED) {
            let response = await dispatch(ACT_PHONE_RTC_CALL_API, {...payload, ...{candidates: prepareCandidates(payload.candidates)}})
            if ('error' in response) {
                dispatch(ACT_PHONE_TERMINATE_CALL, {callId, reason: response.error})
                dispatch(ACT_PHONE_UPDATE_CALL_CON_STATUS, {callId, status: CALL_CONNECTION_STATUSES.ERROR})
            } else {
                let callWorker = getCallWorker(state, callId)
                callId = response.callId // [{callId}]
                payload.callId = callId
                callWorker.onCallResult(null, payload)
                //if (callId) dispatch(`${PHONE_CNTL}/${ACT_UPDATE_ANSWERED_CALL_ID}`, { type: PHONE_TYPES.PHONE, id: payload.rtcCallId, newId: callId }, { root: true })
                //commit(MUT_PHONE_UPDATE_CALL, { callId, callPayload: { inCall: true } })
            }

        }
    },
    [ACT_PHONE_RTC_CALL_TRANSIT]({dispatch, commit, getters}) {
        const pair = getters[GET_PHONE_SIP_PAIR]
        if (pair) {
            const [transitId, callId] = pair
            commit(MUT_PHONE_SET_CALL_TRANSITED, { callId })
            commit(MUT_PHONE_SET_CALL_TRANSITED, { callId: transitId })
            dispatch(ACT_PHONE_RTC_CALL_TRANSIT_API, { callId, transitId })
        }
    },
    [ACT_PHONE_RTC_CALL_CONFERENCE]({dispatch, getters}) {
        const pair = getters[GET_PHONE_SIP_PAIR]
        if (pair) {
            const [confId, callId] = pair
            dispatch(ACT_PHONE_RTC_CALL_CONFERENCE_API, { confId, callId })
        }
    },
    [ACT_PHONE_RTC_CALL_CONF_LIST_UPDATE]({commit, getters, dispatch}, payload) {
        let call = getters[GET_PHONE_CALL](payload.callId)
        dispatch(`${PHONE_CNTL}/${ACT_SET_CALL_PARTICIPANTS}`, {type: PHONE_TYPES.PHONE, id: call[CALL_PROPS.CALL_ID], participants: payload.list}, {root: true})
        commit(MUT_PHONE_CONF_LIST_UPDATE, payload)
    },
    [ACT_PHONE_RTC_CALL_DTMF]({getters, dispatch}, {callId, digits}) {
        callId = getters[GET_PHONE_CALL_SERVER_ID](callId)
        dispatch(ACT_PHONE_RTC_CALL_DTMF_API, {callId, digits})
    },
    [ACT_PHONE_RTC_SET_CALL_STATS]({getters, rootGetters, dispatch, commit}, {callId, stats}) {
        if (stats.callId < 0) return
        let callStatsObj = {}
        callStatsObj.callIncoming = stats.callIncoming
        callStatsObj.callType = stats.callNumber ? 'sip' : stats.mediaType
        const call = getters[GET_PHONE_CALL](callId)
        callStatsObj.callId = call.serverId
        let callState = Object.keys(CALL_CONNECTION_STATUSES).find(key => CALL_CONNECTION_STATUSES[key] === call.connectionStatus)
        callStatsObj.callState = callState.toLowerCase()
        //callStatsObj.connectionState = callState.toLowerCase()
        if (stats.callAbonentCid) callStatsObj.callAbonentCid = stats.callAbonentCid
        if (stats.callAbonent) callStatsObj.callAbonent = stats.callAbonent
        if (stats.callNumber) callStatsObj.callNumber = stats.callNumber
        callStatsObj.callConnectMoment = stats.connectionTime
        callStatsObj.peerSignalingState = stats.peerSignalingState
        callStatsObj.peerIceConnectionState = stats.peerIceConnectionState
        callStatsObj.peerIceGatheringState = stats.peerIceGatheringState
        if (state.peerMuted) callStatsObj.peerMuted = 1
        if (state.peerHold) callStatsObj.peerHold = 1
        if (state.peerRemoteHold) callStatsObj.peerRemoteHold = 1
        callStatsObj.peerLocalAddress = stats.connectionType.local.ipAddress[0]
        callStatsObj.peerLocalCandidateType = stats.connectionType.local.candidateType[0]
        callStatsObj.peerRemoteAddress = stats.connectionType.remote.ipAddress[0]
        callStatsObj.peerRemoteCandidateType = stats.connectionType.remote.candidateType[0]
        callStatsObj.peerTransportType = stats.connectionType.transport
        callStatsObj.peerMos = stats.MOS
        callStatsObj.codec = stats.audio.send.codecs[0]
        callStatsObj.bytesReceived = stats.audio.bytesReceived.toString() || '0'
        callStatsObj.packetsReceived = stats.packetsReceived && stats.packetsReceived.toString() || '0'
        callStatsObj.packetsLost = stats.audio.packetsLost && stats.audio.packetsLost.toString() || '0'
        callStatsObj.delayMs = stats.audio.delayMs && stats.audio.delayMs.toString() || '0'
        callStatsObj.jitterBufferMs = stats.audio.jitterBufferMs && stats.audio.jitterBufferMs.toString() || '0'
        callStatsObj.packetsSend = stats.packetsSent && stats.packetsSent.toString() || '0'
        callStatsObj.bytesSend = stats.audio.bytesSent.toString() || '0'
        //console.log("🚀 ~ file: phone.js:616 ~ stats", stats)

        if (stats.video.send.codecs && stats.video.send.codecs[0]) callStatsObj.videoCodec = stats.video.send.codecs[0] // todo ???
        if (call.serverId && stats.MOS) {
            const data =  { callId: call.serverId, msg: 'stats', data: { mos: stats.MOS }}
            dispatch(ACT_PHONE_RTC_SEND_CALL_PROCESSING, data)
            commit(MUT_PHONE_SET_CALL_MOS, { callId: call.serverId, MOS: stats.MOS })
        }

        if (reportStatus.calls.length) {
            let existedCallStatsIndex = reportStatus.calls.findIndex(call => call.callId === stats.callId)
            //console.log("!! -> file: phone.js -> line 348 -> callWorker.on -> existedCallStats", existedCallStats)
            if (existedCallStatsIndex >= 0) reportStatus.calls.splice(existedCallStatsIndex, 1, callStatsObj)
            else reportStatus.calls.push(callStatsObj)
        } else reportStatus.calls.push(callStatsObj)
        // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//         const clientVersion = rootGetters[`${CLIENTDATA}/${GET_CLIENT_VERSION}`]
//         let verBuildArr = clientVersion.split('.')
//         let sysNameArr = clientVersion.split("/")
//         const softBuild = verBuildArr.pop()
//         const softVersion = verBuildArr.join('.').split('/').pop()
//         const systemName = sysNameArr[0]
//         reportStatus.softBuild = softBuild
//         reportStatus.softVersion = softVersion
//         reportStatus.systemName = systemName
        // #endif
        // reportStatus.carrierCountryCode = ''
        // reportStatus.carrierName = ''
        // reportStatus.localizedModel = ''
        // reportStatus.model = ''
        // reportStatus.network = ''
        // reportStatus.systemVersion = ''
        dispatch(ACT_PHONE_RTC_CALL_SEND_REPORT_API, {report: reportStatus})
    },
};

// mutations
const mutations = {
    setStun(state, stun) {
        state.stun = stun
    },
    [MUT_PHONE_ADD_CALL](state, {callId, callWorker, callPayload}) {
        const call = {...DEFAULT_CALL_DATA, ...{callId, ...callPayload}}
        callWorkers[callId] = callWorker
        state.calls[callId] = call
        state.callsPriority.push(call)
    },
    [MUT_PHONE_UPDATE_CALL](state, {callId, callPayload}) {
        if (callPayload.show) state.show = callPayload.show;
        let call = getCall(state, callId)
        if (!call) return
        /*let newCallId = callPayload.callId
        if (newCallId) {
            delete state.calls[callId]
            state.calls[newCallId] = call
            let callWorker = callWorkers[callId]
            delete callWorkers[callId]
            callWorkers[newCallId] = callWorker
        }*/
        for (let key in callPayload) {
            call[key] = callPayload[key]
        }
    },
    [MUT_PHONE_DELETE_CALL](state, callId) {
        let call = getCall(state, callId)
        let createdId = call[CALL_PROPS.CALL_ID]
        let callWorker = call && getCallWorker(state, createdId)
        if (!call || !callWorker) return
        delete callWorkers[createdId]
        delete state.calls[createdId]
        let existedCallStatsIndex = reportStatus.calls.findIndex(call => call.callId === callId)
        if (existedCallStatsIndex >= 0) reportStatus.calls.splice(existedCallStatsIndex, 1)
        const existedCallMOSIndex = state.callsMOS.findIndex(item => item.callId === call.serverId)
        if (existedCallMOSIndex > -1) state.callsMOS.splice(existedCallMOSIndex, 1)
        let index = state.callsPriority.indexOf(call)
        if (index >= 0) state.callsPriority.splice(index, 1)
        let lastCall = state.callsPriority[state.callsPriority.length - 1]
        if (lastCall) {
            let lastCallId = lastCall[CALL_PROPS.CALL_ID]
            let lastCallWorker = lastCallId && callWorkers[lastCallId]
            lastCallWorker && lastCallWorker.updateStreams()
        }
    },
    [MUT_PHONE_CALL_SELECT](state, callId) {
        let call = getCall(state, callId)
        let index = state.callsPriority.indexOf(call)
        let last = state.callsPriority[state.callsPriority.length - 1]
        if (call && index >= 0 && index !== last) {
            state.callsPriority.splice(index, 1)
            state.callsPriority.push(call)
        }
    },
    [MUT_PHONE_HIDE](state) {
        ipc.send('phone-close')
    },
    [SAVE_SCREEN_SIZE]: (state, { width, height }) => {
        state.screenSize.width = width
        state.screenSize.height = height
    },
    [MUT_PHONE_SET_CALL_DELETE_TIMEOUT]: (state, {callId, timeOut}) => {
        let call = getCall(state, callId)
        if (!call) return
        if (call.deleteTimeOut) clearTimeout(call.deleteTimeOut)
        call.deleteTimeOut = timeOut
    },
    [MUT_PHONE_SET_CALL_TRANSITED]: (state, { callId }) => {
        let call = getCall(state, callId)
        if (!call) return
        call[CALL_PROPS.TRANSITED] = true
    },
    [MUT_PHONE_CONF_LIST_UPDATE]: (state, { callId, list }) => {
        let call = getCall(state, callId)
        if (!call) return
        call[CALL_PROPS.PARTICIPANTS] = list
    },
    [MUT_PHONE_SET_CALL_MOS]: (state, { callId, MOS = null, remoteMOS = null }) => {
        let existedCallMOS = state.callsMOS.find(call => callId && call.callId === callId)
        if (existedCallMOS) {
            if (MOS) existedCallMOS.MOS = MOS
            if (remoteMOS) existedCallMOS.remoteMOS = remoteMOS
        }
        else if (callId && MOS) state.callsMOS.push({ callId, MOS, remoteMOS })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

function prepareCandidates (candidates) {
    return candidates.map(candidate => ({
        candidate: candidate.candidate,
        sdpMLineIndex: candidate.sdpMLineIndex,
        sdpMid: candidate.sdpMid
    }))
}

function getCall(state, callId) {
    if (callId < 0) {
        return state.calls[callId]
    } else {
        return state.callsPriority.find(({serverId}) => {
            return serverId === callId
        })
    }
}

function getCallWorker(state, callId) {
    if (callId < 0) {
        return callWorkers[callId]
    } else {
        let call = getCall(state, callId)
        return call && callWorkers[call[CALL_PROPS.CALL_ID]]
    }
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "context-menu-base",
    _vm._b({}, "context-menu-base", _vm.menuProps, false),
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("context-menu-group", {
            attrs: {
              header: _vm.$t("videomost.devices.choose-microphone"),
              select: true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._l(_vm.micList, function (item) {
                      return _c(
                        "context-menu-item",
                        _vm._b(
                          {
                            key: item.id,
                            attrs: { text: item.text, selected: item.selected },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onSelected(item.kind, item.id)
                              },
                            },
                          },
                          "context-menu-item",
                          props,
                          false
                        )
                      )
                    }),
                    !_vm.micList.length
                      ? _c("span", { staticClass: "not-found" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("videomost.devices.no-microphones-found")
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("context-menu-group", {
            attrs: {
              header: _vm.$t("videomost.devices.choose-speaker"),
              select: true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._l(_vm.speakerList, function (item) {
                      return _c(
                        "context-menu-item",
                        _vm._b(
                          {
                            key: item.id,
                            attrs: { text: item.text, selected: item.selected },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onSelected(item.kind, item.id)
                              },
                            },
                          },
                          "context-menu-item",
                          props,
                          false
                        )
                      )
                    }),
                    !_vm.speakerList.length
                      ? _c("span", { staticClass: "not-found" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("videomost.devices.no-speakers-found")
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
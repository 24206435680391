var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity-line",
      style: _vm.styles,
      on: { click: _vm.toggleCall },
    },
    [
      _c("div", { staticClass: "left-section" }, [
        _c("span", [_vm._v(_vm._s(_vm.leftText))]),
      ]),
      _c("div", { staticClass: "central-section" }, [
        _c("div", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "right-section" }, [
        _c(
          "div",
          {
            staticClass: "icon-wrapper circle-animation",
            attrs: { title: _vm.$t("phone.end-call") },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.endCall($event)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-phone" })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
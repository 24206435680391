var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "channel", staticClass: "main-body", attrs: { id: "channel" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.visible,
              expression: "!visible",
            },
          ],
          staticClass: "message-list-preload",
        },
        [_c("i", { staticClass: "fal fa-spinner fa-spin" })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "publications-list",
          attrs: { id: "publications-list" },
          on: { scroll: _vm.doOnScroll },
        },
        [
          _c(
            "ul",
            { staticClass: "list" },
            [
              _vm.invited
                ? _c("channel-invite", { attrs: { "ch-id": _vm.chId } })
                : _vm._e(),
              _vm._l(_vm.publications, function (publication) {
                return _c("publication", {
                  key: publication.pubId,
                  attrs: { "ch-id": _vm.chId, "pub-id": publication.pubId },
                })
              }),
            ],
            2
          ),
          _vm.loadingPublication
            ? _c("div", { staticClass: "publication-list-preload" }, [
                _c("i", { staticClass: "fal fa-spinner fa-spin" }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
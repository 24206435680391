//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { CONTACTS, CHAT } from "../../../store/modulesNames"
import { GET_MERGED_CONTACT_BY_ID } from "../../../store/gettersTypes"
import { ACT_CHAT_SHOW_REACTION_PANEL } from "../../../store/actionsTypes"
import ContactListItem from '../../../components/listItems/ContactsListItem.vue'

export default {
    name: "Reactions",
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        reactions: {
            type: Object,
        },
        panelStyle: {},
    },
    components: { ContactListItem },
    data() {
        return {
            isMouseOver: false,
            topReactionFilter: '',
            tmpContacts: [],
            isAfterTopReactionClick: false
        }
    },
    computed: {
        contacts() {
            let cidsReaction = []
            if (!this.topReactionFilter) cidsReaction = this.reactions.reactions.map(r => { return {cid: r.cid, reaction: r.reaction}})
            else {
                const filteredReactions = this.reactions.reactions.filter(r => r.reaction === this.topReactionFilter)
                cidsReaction = filteredReactions.map(r => { return { cid: r.cid, reaction: this.topReactionFilter}})
            }
            const cnts = []
            cidsReaction.forEach(cr => {
                let contact = this.tmpContacts.length && this.tmpContacts.filter(c => c.cid === cr.cid)[0]
                if (!contact) {
                    contact = this[GET_MERGED_CONTACT_BY_ID](cr.cid)
                    this.tmpContacts.push(contact)
                }
                cnts.push({contact, reaction: cr.reaction})
            })
            return cnts
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        mousePanelOver(e) {
            e.stopPropagation()
        },
        mousePanelLeave(e) {
            if (e.toElement.className !== 'reactions-panel-wrapper' && !this.isAfterTopReactionClick) {
                this[ACT_CHAT_SHOW_REACTION_PANEL]({ isShow: false})
            }
        },
        topReactionClick(e, reaction = null) {
            e.preventDefault()
            e.stopPropagation()
            this.isAfterTopReactionClick = true
            reaction ? this.topReactionFilter = reaction : this.topReactionFilter = ''
            setTimeout(() => {
                this.isAfterTopReactionClick = false
            }, 1000)
        },
        ...mapActions(CHAT, [ACT_CHAT_SHOW_REACTION_PANEL]),
    },

}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.doOnClick } },
    [
      _c("icon-image", { attrs: { src: _vm.photo, text: _vm.text } }),
      _c("div", { staticClass: "contact-name name-text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
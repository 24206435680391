var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          {
            staticClass: "survey-msg-content",
            style: "display: flex; justify-content: center;",
          },
          [
            _vm.isIFrameLoading
              ? _c("content-loader", { style: "background: transparent;" })
              : _vm._e(),
            _c("iframe", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isIFrameLoading,
                  expression: "!isIFrameLoading",
                },
              ],
              ref: "surveyIFrame",
              attrs: { id: "surveyIFrame", src: _vm.link },
              on: { load: _vm.iFrameLoaded },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.onCancel } }, [
          _vm._v(_vm._s(_vm.$t("close"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
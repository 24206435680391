var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "chat-create" }, [
          _c(
            "div",
            { staticClass: "first-line" },
            [
              _c("icon-image", {
                staticClass: "chat-icon extra-big editable",
                attrs: {
                  src: _vm.image,
                  remove: _vm.iconRemove,
                  rollBack: _vm.iconRollback,
                },
                on: {
                  rollBack: _vm.doRollbackImage,
                  remove: _vm.doRemoveImage,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.doFileSelect($event)
                  },
                },
              }),
              _c("div", { staticClass: "icon-label" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("chat-img")))]),
              ]),
            ],
            1
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            staticClass: "form-control non-border-input",
            class: { "warning-input": _vm.warning },
            attrs: { type: "text", placeholder: _vm.namePlaceholder },
            domProps: { value: _vm.name },
            on: {
              click: _vm.checkInput,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              },
            },
          }),
          _c("input", {
            ref: "fileLoader",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.doLoadImage },
          }),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.modalClose } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.onOkClicked } },
          [_vm._v(_vm._s(_vm.okText))]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
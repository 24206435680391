var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.id
    ? _c(
        "div",
        { staticClass: "incomming-call" },
        [
          _c("div", { staticClass: "close-btn", on: { click: _vm.hide } }, [
            _c("i", { staticClass: "fas fa-times" }),
          ]),
          _c("icon-image", {
            staticClass: "icon-big",
            attrs: { src: _vm.icon, text: _vm.name },
          }),
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _c("div", { staticClass: "type" }, [
            _vm._v(_vm._s(_vm.callTypeNames.title)),
          ]),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _vm.callTypeNames.type
                ? _c("phone-action-button", {
                    attrs: { type: _vm.callTypeNames.type },
                    on: { click: _vm.answer },
                  })
                : _vm._e(),
              _c("phone-action-button", {
                attrs: { type: "terminate" },
                on: { click: _vm.terminate },
              }),
            ],
            1
          ),
          _c("custom-audio", {
            ref: "callAudio",
            attrs: {
              src: _vm.ringToneSrc,
              autoplay: true,
              loop: true,
              type: "audio/mpeg",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
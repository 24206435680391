var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "context-menu-base",
    _vm._b({}, "context-menu-base", _vm.menuProps, false),
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("context-menu-group", {
            attrs: { header: _vm.title },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "conf-info",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _vm._l(_vm.details, function (item, i) {
                          return _c("list-item", {
                            key: i,
                            staticClass: "item",
                            attrs: {
                              header: item.title,
                              title: item.text,
                              compact: true,
                            },
                          })
                        }),
                        _c(
                          "list-item",
                          {
                            staticClass: "item color-blue copy-item",
                            attrs: {
                              title: _vm.$t(
                                "videomost.conf-members-copyconflink"
                              ),
                              link: true,
                              chevron: false,
                              compact: true,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.copyLink($event)
                              },
                            },
                          },
                          [
                            _c(
                              "custom-round-btn",
                              {
                                attrs: {
                                  slot: "media",
                                  btnSize: "30px",
                                  "icon-color": "inherit",
                                  "position-left": true,
                                },
                                slot: "media",
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-clipboard",
                                  attrs: { slot: "default" },
                                  slot: "default",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
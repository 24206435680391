var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-section-share" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("videomost.room-btns.participants")))]),
    ]),
    _c(
      "div",
      { staticClass: "minimize-screen sec-btn" },
      [
        _c("conf-btn", {
          staticClass: "exit-btn",
          attrs: { imageClasses: "fal fa-chevron-down", size: 22 },
          on: { click: _vm.toggleMinimize },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-list" },
    [
      _c("RecycleScroller", {
        ref: "scroller",
        staticClass: "scroller",
        attrs: {
          items: _vm.contactsList,
          "item-size": _vm.itemSize,
          "key-field": "cid",
          buffer: _vm.bufferSize,
          emitUpdate: _vm.isEmitIndexes,
        },
        on: { update: _vm.emitUpdate },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c(
                    "div",
                    { key: item.cid, staticClass: "contact-item" },
                    [_vm._t("default", null, { item: item, i: index })],
                    2
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
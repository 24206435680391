var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "acs-absence-reason" }, [
    _c("div", { staticClass: "wrapper-acs-absence-reason small" }, [
      _c("div", { staticClass: "reason-title" }, [
        _vm._v(_vm._s(_vm._f("statusToText")(_vm.currentJournal.status))),
      ]),
      _c("div", { staticClass: "reason-info" }, [
        _vm.currentJournal.text
          ? _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.currentJournal.text)),
            ])
          : _vm._e(),
        _vm.currentJournal.comment
          ? _c("span", { staticClass: "comment" }, [
              _vm._v(
                _vm._s(_vm.currentJournal.text ? ". " : "") +
                  _vm._s(_vm.currentJournal.comment)
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "reason-time date" }, [
        _vm._v(
          _vm._s(_vm._f("toDataFormat")(_vm.currentJournal.from)) +
            " - " +
            _vm._s(_vm._f("toDataFormat")(_vm.currentJournal.to))
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content-container" }, [
    _vm.has_header_slot
      ? _c("div", { staticClass: "main-content-container-header" }, [
          _c("p", [_vm._t("header")], 2),
        ])
      : _vm._e(),
    _vm.has_default_slot
      ? _c(
          "div",
          { staticClass: "main-content-container-main" },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm.has_footer_slot
      ? _c("div", { staticClass: "main-content-container-footer" }, [
          _c("p", [_vm._t("footer")], 2),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
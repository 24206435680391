var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "audio-message",
      class: { "audio-active": _vm.recordingAudioMessage },
    },
    [
      _vm.recordingAudioMessage
        ? _c("div", { staticClass: "audio-message-info" }, [
            _c("span", { staticClass: "voice-message" }, [
              _vm._v(_vm._s(_vm.$t("voice-message"))),
            ]),
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                { staticClass: "recording-btns buttons circle-animation" },
                [
                  _c("i", {
                    staticClass: "fal fa-times-circle",
                    attrs: { size: "5x" },
                    on: { click: _vm.cancelVoiceMessage },
                  }),
                ]
              ),
              _c("div", { staticClass: "recording-time" }, [
                _c("i", {
                  staticClass: "fas fa-circle recording-circle",
                  attrs: { size: "2x" },
                }),
                _c("span", [_vm._v(_vm._s(_vm.recordTimeText))]),
              ]),
              _c(
                "div",
                { staticClass: "recording-btns buttons circle-animation" },
                [
                  _c("i", {
                    staticClass: "fal fa-check-circle",
                    on: { click: _vm.sendVoiceMessage },
                  }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
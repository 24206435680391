var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "phone-audio-icons",
      class: _vm.pause ? "pause" : "",
      style: _vm.cssVars,
    },
    _vm._l(_vm.icons, function (icon, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "icon",
          style: { "z-index": _vm.icons.length - index + 1 },
        },
        [
          _c("icon-image", {
            staticClass: "icon-image",
            attrs: { src: icon.src, text: icon.text },
          }),
          _vm.pause && index === 0
            ? _c(
                "div",
                {
                  staticClass: "icon-pause-btn impulse-animation-background",
                  on: { click: _vm.togglePause },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pause impulse-animation-color",
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
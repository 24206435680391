var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-channel-message", on: { click: _vm.openChannel } },
    [
      _vm.message.author
        ? _c("div", { staticClass: "forward" }, [
            _c("span", { staticClass: "fwd title" }, [
              _c("i", { staticClass: "fas fa-share" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
            ]),
            _vm.fioById(_vm.message.author)
              ? _c("span", { staticClass: "fwd author" }, [
                  _vm._v(
                    _vm._s(_vm.$t("chat.author")) +
                      ": " +
                      _vm._s(_vm.fioById(_vm.message.author))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "chat-channel-info" }, [
        _c("div", { staticClass: "channel-name" }, [
          _c("span", [_vm._v(_vm._s(_vm.name))]),
        ]),
        _c("div", { staticClass: "channel-subscribers" }, [
          !_vm.channel
            ? _c("span", [_vm._v(_vm._s(_vm.$t("chat.no-permission")))])
            : _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("chat.subs")) + " - " + _vm._s(_vm.subscribers)
                ),
              ]),
        ]),
      ]),
      _c("icon-image", {
        staticClass: "rectangular",
        attrs: { src: _vm.photo, text: _vm.name },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
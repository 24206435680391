var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "publication-footer" }, [
    _c("div", { staticClass: "publication-footer-icons" }, [
      _c(
        "div",
        {
          staticClass: "likes-section icon-section",
          on: {
            click: function ($event) {
              return _vm.doLikePublication(_vm.publication.like)
            },
          },
        },
        [
          _c("i", {
            staticClass: "fal fa-heart",
            class: _vm.publication.like ? "liked" : "",
          }),
          _vm.publication.likesCount
            ? _c("div", { staticClass: "likes-count" }, [
                _vm._v(_vm._s(_vm.publication.likesCount)),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "comments-section icon-section",
          on: {
            click: function ($event) {
              return _vm.doOpenComments(_vm.publication.pubId)
            },
          },
        },
        [
          _c("i", { staticClass: "fal fa-comment" }),
          _vm.publication.commentsCount
            ? _c("div", { staticClass: "comments-count" }, [
                _vm._v(_vm._s(_vm.publication.commentsCount)),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "reply-section icon-section" }, [
        _c("i", {
          staticClass: "fal fa-share",
          on: { click: _vm.replyPublication },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { editing: _vm.edit },
      attrs: { contenteditable: _vm.edit },
      on: {
        input: _vm.update,
        blur: _vm.update,
        keyup: _vm.update,
        paste: _vm.update,
      },
    },
    [_vm._v(_vm._s(_vm.text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "acs-status-wrapper",
      class: { "om-verbose": !_vm.show_link },
      on: { click: _vm.doSwitchToOmJournal },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "acs-spinner-wrapper",
        },
        [_vm._m(0)]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAcsStatus,
              expression: "showAcsStatus",
            },
          ],
          staticClass: "acs-status",
          class: { verbose: !_vm.show_link },
        },
        [
          _c(
            "div",
            {
              staticClass: "acs-status-left",
              class: { verbose: !_vm.show_link },
            },
            [
              _c("span", { staticClass: "head" }, [
                _vm._v(_vm._s(_vm._f("acsTypeResolve")(_vm.location.type))),
              ]),
              _c(
                "span",
                {
                  staticClass: "status",
                  class: { "color-accent": _vm.location.colorAccent },
                },
                [_vm._v(_vm._s(_vm._f("locationToStatusText")(_vm.location)))]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "acs-status-right",
              class: { verbose: !_vm.show_link },
            },
            [
              _c("span", { staticClass: "head" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("locationToRightText")(
                      _vm._f("timeToFormat")(_vm.location)
                    )
                  )
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show_link,
                  expression: "show_link",
                },
              ],
              staticClass: "acs-link",
            },
            [_c("i", { staticClass: "fal fa-angle-right" })]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.show_link && _vm.entrancesBtnShow,
                  expression: "!show_link && entrancesBtnShow",
                },
              ],
              staticClass: "entraces-toggle",
              on: { click: _vm.entrancesToggle },
            },
            [
              _c("i", {
                staticClass: "fal",
                class: _vm.showEntrances ? "fa-chevron-up" : "fa-chevron-down",
              }),
            ]
          ),
        ]
      ),
      _c("acs-entrances", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.show_link && _vm.showEntrances && _vm.location.time,
            expression: "!show_link && showEntrances && location.time",
          },
        ],
        staticClass: "additional-block",
        attrs: { contact_id: _vm.contact_id },
      }),
      _c("acs-absence-reason", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.show_link &&
              !_vm.loading &&
              _vm.currentJournal &&
              _vm.currentJournal.status,
            expression:
              "!show_link && !loading && currentJournal && currentJournal.status",
          },
        ],
        staticClass: "additional-block",
        attrs: { currentJournal: _vm.currentJournal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "acs-spinner" }, [
      _c("i", { staticClass: "fal fa-spinner fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
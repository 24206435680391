//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue'

import { CHANNEL } from '../../store/modulesNames.js'
import { ACT_OPEN_CHANNEL } from '../../store/actionsTypes.js'
import { MUT_SCROLL_TO_PUBLICATION } from '../../store/mutationsTypes.js'

export default {
    name: 'notification',
    components: {
        'icon-image': IconImage
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        fio: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        }
    },
    methods: {
        doOnClickNotification() {
            if (this.type === 'publication') {
                this.$store.dispatch(`${CHANNEL}/${ACT_OPEN_CHANNEL}`, this.data.chId)
                this.$store.commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, { chId: this.data.chId, pubId: this.data.pubId})
            } else if (this.type === 'message') {
                this.$store.dispatch('chats/open', {cid: this.data.cid, cidType: this.data.cidType})
            }
            this.$emit('hide')
        },
        hide(e) {
            e.stopPropagation()
            this.$emit('hide')
        }
    }
}

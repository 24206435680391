var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("custom-radio", {
              attrs: {
                inputs: _vm.inputs,
                default_data: _vm.birthdayNotify,
                value_change: _vm.birthdayNotify,
              },
              on: {
                "update:value_change": function ($event) {
                  _vm.birthdayNotify = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.cancelBtn } }, [
          _vm._v(_vm._s(_vm.$t("cancel"))),
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.okBtn } }, [
          _vm._v(_vm._s(_vm.$t("apply"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
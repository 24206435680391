var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.notifications")))]),
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                {
                  staticClass: "audio-toggle-btn message-btn",
                  on: {
                    click: function ($event) {
                      return _vm.togglePlaySound(_vm.notifAudio)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa",
                    class: _vm.playNotifAudio ? "fa-pause" : "fa-play",
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("i", { staticClass: "fas fa-volume-off" }),
                  _c("vue-slider", {
                    key: "notif",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "1",
                    },
                    model: {
                      value: _vm.notificationSliderValue,
                      callback: function ($$v) {
                        _vm.notificationSliderValue = $$v
                      },
                      expression: "notificationSliderValue",
                    },
                  }),
                  _c("i", { staticClass: "fas fa-volume-up volume-up" }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("calls")))]),
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                {
                  staticClass: "audio-toggle-btn message-btn",
                  on: {
                    click: function ($event) {
                      return _vm.togglePlaySound(_vm.ringAudio)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa",
                    class: _vm.playRingAudio ? "fa-pause" : "fa-play",
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("i", { staticClass: "fas fa-volume-off" }),
                  _c("vue-slider", {
                    key: "calls",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "1",
                    },
                    model: {
                      value: _vm.ringSliderValue,
                      callback: function ($$v) {
                        _vm.ringSliderValue = $$v
                      },
                      expression: "ringSliderValue",
                    },
                  }),
                  _c("i", { staticClass: "fas fa-volume-up volume-up" }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "wrapper-item" }, [
            _c("div", { staticClass: "item-header" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("radio")))]),
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c(
                "div",
                {
                  staticClass: "audio-toggle-btn message-btn",
                  on: {
                    click: function ($event) {
                      return _vm.togglePlaySound(_vm.radioAudio)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa",
                    class: _vm.playRadioAudio ? "fa-pause" : "fa-play",
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "range-slider" },
                [
                  _c("i", { staticClass: "fas fa-volume-off" }),
                  _c("vue-slider", {
                    key: "radio",
                    staticClass: "slider",
                    attrs: {
                      disabled: _vm.sliderDisabled,
                      min: "0",
                      max: "100",
                      step: "1",
                    },
                    model: {
                      value: _vm.radioSliderValue,
                      callback: function ($$v) {
                        _vm.radioSliderValue = $$v
                      },
                      expression: "radioSliderValue",
                    },
                  }),
                  _c("i", { staticClass: "fas fa-volume-up volume-up" }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.modalClose()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c("div", { staticClass: "btn", on: { click: _vm.okBtn } }, [
          _vm._v(_vm._s(_vm.$t("apply"))),
        ]),
      ]),
      _c("audio", {
        ref: _vm.notifAudio,
        attrs: { src: _vm.notificationSoundSrc },
        on: {
          ended: function ($event) {
            return _vm.stopPlay($event, _vm.notifAudio)
          },
        },
      }),
      _c("audio", {
        ref: _vm.ringAudio,
        attrs: { src: _vm.ringSoundSrc },
        on: {
          ended: function ($event) {
            return _vm.stopPlay($event, _vm.ringAudio)
          },
        },
      }),
      _c("audio", {
        ref: _vm.radioAudio,
        attrs: { src: _vm.radioSoundSrc },
        on: {
          ended: function ($event) {
            return _vm.stopPlay($event, _vm.radioAudio)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
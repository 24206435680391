var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    _vm._b(
      {
        staticClass: "custom-modal",
        attrs: { name: _vm.name },
        on: { closed: _vm.onClosed },
      },
      "modal",
      _vm.modalProps,
      false
    ),
    [
      _vm.hasHeader
        ? _c(
            "div",
            { staticClass: "header" },
            [
              _vm._t("header"),
              _vm.title
                ? _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _vm._e(),
              _vm.headerClose
                ? _c(
                    "div",
                    { staticClass: "close-btn", on: { click: _vm.close } },
                    [_c("i", { staticClass: "fas fa-times" })]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.hasDefaultSlot
        ? _c("div", { staticClass: "body" }, [_vm._t("default")], 2)
        : _vm._e(),
      _vm.hasFooterSlot
        ? _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-input-wrapper", style: _vm.plStyle },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search",
          },
        ],
        staticClass: "search-input",
        attrs: {
          type: "text",
          placeholder: _vm.placeholderString,
          readonly: _vm.checkReadOnly(),
        },
        domProps: { value: _vm.search },
        on: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.onKeyDown($event)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.onKeyUp($event)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onEnter($event)
            },
          ],
          focus: _vm.onFocus,
          blur: function ($event) {
            _vm.readonly = true
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          },
        },
      }),
      _c("div", { staticClass: "btns-block" }, [
        _vm.filter
          ? _c(
              "div",
              { staticClass: "filter-btn", on: { click: _vm.onFilter } },
              [_c("i", { staticClass: "far fa-sliders-v" })]
            )
          : _vm._e(),
        _vm.search && _vm.clearable
          ? _c(
              "div",
              { staticClass: "clearSearch", on: { click: _vm.clearSearch } },
              [_c("i", { staticClass: "fal fa-times" })]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { class: _vm.isHorizontal ? "horizontal" : "" },
    [
      _vm.header
        ? _c("span", { staticClass: "header-text" }, [
            _vm._v(_vm._s(_vm.header)),
          ])
        : _vm._e(),
      _vm._l(_vm.inputs, function (input) {
        return _c(
          "label",
          {
            staticClass: "custom-radio",
            class: _vm.isDisabledClass(input),
            style: input.style,
          },
          [
            _vm._v(_vm._s(input.label)),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value",
                },
              ],
              class: input.isDisabled ? "isDisabled" : "isEnabled",
              attrs: {
                type: "radio",
                disabled: _vm.isDisabled || input.isDisabled,
              },
              domProps: {
                value: input.value,
                checked: _vm._q(_vm.value, input.value),
              },
              on: {
                change: function ($event) {
                  _vm.value = input.value
                },
              },
            }),
            _c("i", { staticClass: "fal fa-circle" }),
            input.description
              ? _c("div", { staticClass: "description" }, [
                  _vm._v(_vm._s(input.description)),
                ])
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
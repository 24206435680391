var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-block" },
    [
      _c("div", { staticClass: "main-header" }, [
        _c(
          "div",
          { staticClass: "section-header", attrs: { id: "main-header" } },
          [
            _c("div", { staticClass: "header-text" }, [
              _vm._v(" " + _vm._s(_vm.$t("videomost.menu-conferences"))),
            ]),
            _c("div", { staticClass: "menu-icons" }, [
              _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  attrs: { title: _vm.$t("videomost.conf-run") },
                  on: { click: _vm.newConference },
                },
                [_c("i", { staticClass: "fal fa-video" })]
              ),
              _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  attrs: { title: _vm.$t("videomost.enter-inconference") },
                  on: { click: _vm.enterConference },
                },
                [_c("i", { staticClass: "fal fa-arrow-alt-right" })]
              ),
              _c(
                "div",
                {
                  staticClass: "circle-animation conference",
                  attrs: { title: _vm.$t("videomost.new-conference") },
                  on: { click: _vm.planNewConf },
                },
                [_c("i", { staticClass: "fal fa-calendar-alt" })]
              ),
            ]),
          ]
        ),
      ]),
      _vm.loader
        ? _c("main-content-loader")
        : _c(
            "div",
            { staticClass: "videoconferences" },
            [
              _c(
                "main-content-container",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "items-list" },
                            [
                              !_vm.confPlanned.length
                                ? [
                                    _c("list-item", {
                                      staticClass: "vm-item no-confs",
                                      attrs: {
                                        title: "",
                                        footer: _vm.$t(
                                          "videomost.no-planned-conf"
                                        ),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.confPlanned.length
                                ? _vm._l(_vm.confPlanned, function (conf, i) {
                                    return _c("list-item", {
                                      key: i,
                                      staticClass: "vm-item",
                                      attrs: {
                                        title: conf.topic,
                                        footer:
                                          _vm.$t("settings.starts") +
                                          " " +
                                          _vm.getLocalTime(conf.startUTime),
                                        link: "",
                                        chevron: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.confInfo(conf)
                                        },
                                        contextmenu: function ($event) {
                                          return _vm.openContextMenu(
                                            $event,
                                            conf
                                          )
                                        },
                                      },
                                    })
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(_vm._s(_vm.$t("videomost.planned-conferences"))),
                  ]),
                ],
                2
              ),
              _vm.confPassed.length
                ? _c(
                    "main-content-container",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "items-list" },
                                  _vm._l(_vm.confPassed, function (conf, i) {
                                    return _c("list-item", {
                                      key: i,
                                      staticClass: "vm-item",
                                      attrs: {
                                        title: conf.topic,
                                        footer:
                                          _vm.$t("settings.starts") +
                                          " " +
                                          _vm.getLocalTime(conf.startUTime),
                                        link: "",
                                        chevron: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.confInfo(conf)
                                        },
                                        contextmenu: function ($event) {
                                          return _vm.openContextMenu(
                                            $event,
                                            conf
                                          )
                                        },
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3280948212
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v(_vm._s(_vm.$t("videomost.passed-conferences"))),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/** bots **/
export const MUT_ADD_BOT = 'addBot';
export const MUT_CHANGE_BOT = 'changeBot';
export const MUT_DELETE_BOT = 'deleteBot';
export const MUT_CHANGE_BOT_PROP = 'mutChangeBotProp';
export const MUT_KEYBOARD_CHATS = 'keyboardChat';

/** Login **/
export const MUT_SET_RELOGIN_INTERVAL = 'setReloginInterval';
export const MUT_CLEAR_RELOGIN_INTERVAL = 'clearReloginInterval';
export const MUT_SET_LOGINED_STATE = 'setLoginedState';
export const MUT_SET_LOADED = 'setLoaded';
export const MUT_SET_LOGIN_DATA = 'setLoginData';
export const MUT_DAYS_IN_OPERATION = 'mutDaysInOperation';
export const MUT_SET_SERVER_API = 'setServerAPI';
export const MUT_SET_LOGIN_TOKENS = 'mutSaveLoginTokens';
export const MUT_LOGIN_DROP_TOKENS = 'mutLoginDropTokens';

/** Socket **/
export const MUT_SOCKET_SET_CONNECTION_STATUS = 'mutSocketSetConnectionStatus';
export const MUT_SOCKET_SET_RECONNECT_TIMEOUT = 'mutSocketSetReconnectTimeout';
export const MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT = 'mutSocketCleatReconnectTimeout';
export const MUT_SOCKET_SET_ENCRYPTION = 'mutSocketSetEncryption';

/** ContentManager **/
export const MUT_MAIN_TYPE_PUSH = 'mutMainTypePush';
export const MUT_MAIN_TYPE_REPLACE = 'mutMainTypeReplace';
export const MUT_MAIN_TYPE_BACK = 'mutMainTypeBack';
export const MUT_MAIN_TYPE_CLEAR = 'mutMainTypeClear';
export const MUT_SET_SIDE_TYPE = 'setSideType';
export const MUT_SET_ACTIVITY_TYPE = 'mutSetActivityType';
export const MUT_SET_DOCUMENT_HIDDEN = 'setDocumentHidden';
export const MUT_SET_DOCUMENT_FOCUS = 'setDocumentFocus';
export const MUT_SET_SEARCH_OPENED = 'setSearchOpened';
export const MUT_INFO_NOTIFICATION = 'mutInfoNotification';
export const MUT_SET_SIDE_BAR_NOTIFICATION = 'mutSetSideBarNotification'
export const MUT_DELETE_SIDE_BAR_NOTIFICATION = 'mutDeleteSideBarNotification'

/** Contacts **/
export const MUT_SET_SELECTED_CONTACT = 'selected';
export const MUT_SET_SUPPORT = 'setSupport';
export const MUT_CLEAR_CONTACTS = 'clear';

/** Channels **/
export const MUT_SET_SELECTED_CHANNEL = 'selected';
export const MUT_CLEAR_CHANNELS = 'clear';

/** Channel **/
export const MUT_SCROLL_TO_PUBLICATION = 'ScrollToPublication';
export const MUT_CHANNEL_SET_SEARCH = 'mutChannelSetSearch'

/** Chats **/
export const MUT_SET_SELECTED_CHAT = 'selected';
export const ADD_UNWATCHED_MESSAGE = 'addUnwatchedMessage';
export const MUT_CLEAR_CHATS = 'clear';
export const MUT_ADD_DELAYED_UNWATCHED_MESSAGE = 'addDelayedUnwatchedMessage';
export const MUT_REMOVE_DELAYED_UNWATCHED_MESSAGE = 'removeDelayedUnwatchedMessage';
export const MUT_UPDATE_CHAT_LAST_MESSAGE = 'updateChatLastMessage'
export const MUT_SAVE_CHAT_DATA = 'saveChatData'
export const MUT_SET_CHAT_DRAFT = 'setChatDraft'

/** Chat **/
export const MUT_SELECTED_MESSAGE = 'updateReply';
export const MUT_CHAT_SCROLL_UP = 'mutChatScrollUp';
export const MUT_SET_MSG_ENTITIES = 'mutSetMessageEntities'
export const MUT_CHAT_SET_SEARCH = 'mutChatSetSearch'
export const MUT_CHAT_SET_MEDIA_SEARCH = 'mutChatSetMediaSearch'
export const MUT_SET_SELECT_MODE = 'mutSetSelectMode'
export const MUT_SET_SELECTED_MSGS = 'mutSetSelectedMessages'
export const MUT_DELETE_SELECTED_MSGS = 'mutDeleteSelectedMessages'
export const MUT_UPDATE_MESSAGE_REACTION = 'mutUpdateMessageReaction'
export const MUT_SHOW_REACTION_PANEL = 'mutShowReactionPanel'
export const MUT_SHOW_REACTION_PICKER = 'mutShowReactionPicker'

/** UserData **/
export const MUT_SET_USER_PARAMS = 'setUserParams';
export const MUT_SET_SERVER_INFO = 'mutSetServerInfo'
export const MUT_SET_UID = 'setUid';
export const MUT_SET_LOGIN = 'setLogin';
export const MUT_SET_PARAMS = 'setParams';
export const MUT_SET_CURRENT_PANE = 'setCurrentPane';
export const MUT_SEND_BY_ENTER = 'setSendByEnter';
export const MUT_SET_CONTACT_STATUS = 'mutSetContactStatus';
export const MUT_SET_BIRTHDAY_NOTIFY = 'setBirthdayNotify';
export const MUT_SET_SEARCHED_CONTACTS = 'mutSetSearchContacts';
export const MUT_CLEAR_SEARCHED_CONTACTS = 'mutClearSearchContacts';
export const MUT_VOLUME_NOTIFICATION = 'mutVolumeNotification'
export const MUT_VOLUME_RING = 'mutVolumeRing';
export const MUT_VOLUME_RADIO = 'mutVolumeRadio';
export const MUT_VOLUME_AUDIO = 'mutVolumeSound';
export const MUT_VOLUME_MICROPHONE = 'mutVolumeMicrophone';
export const MUT_WEBCAMERA_ENABLE = 'mutWebcameraEnable';
export const MUT_MICROPHONE_ENABLE = 'mutMicrophoneEnable';
export const MUT_ACTIVE_SPEAKERS = 'mutActiveSpeakers';
export const MUT_ACTIVE_MICROPHONE = 'mutActiveMicrophone';
export const MUT_ACTIVE_WEBCAMERA = 'mutActiveWebcamera';
export const MUT_MEDIA_DEVICES = 'mutMediaDevices'
export const MUT_SET_MEDIA_DEVICE_LAST_OK = 'mutSetMediaDeviceLastOk'
export const MUT_SAVE_INTEGRATION_SETTINGS = 'mutSaveIntegrationSettings'
export const MUT_CLEAR_INTEGRATION_SETTINGS = 'mutClearIntegrationSettings'
export const MUT_SET_SERVER_LIST = 'mutSetServerList';

/** Acs **/
export const MUT_ACS_SET_UPDATE_INTERVAL = 'setUpdateInterval';
export const MUT_ACS_SET_CLEAR_INTERVAL = 'setClearInterval';
export const MUT_ACS_SET_CID = 'setCid';
export const MUT_ACS_SET_CONTACTS = 'setContacts';

export const MUT_ACS_SET_ROLE = 'setRole';
export const MUT_ACS_SET_TYPE = 'setType';
export const MUT_ACS_SET_OM_IS_ADMIN = 'setIsAdmin';
export const MUT_ACS_SET_OM_LOGIN = 'setOmLogin';
export const MUT_ACS_SET_OM_PASSWORD = 'setOmPassword';
export const MUT_ACS_UPDATE_CONTACT_INFO = 'updateContactInfo';
export const MUT_ACS_DELETE_CONTACT_INFO = 'deleteContactInfo';
export const MUT_ACS_ADD_JOURNAL_RECORD = 'addJournalRecord';
export const MUT_ACS_DELETE_JOURNAL_RECORD = 'delJournalRecord';

/** Alarm **/
export const MUT_ALARM_SET_PROPS = 'setAlarmProps';

/** Phone **/
export const MUT_PHONE_ADD_CALL = 'mutPhoneAddCall';
export const MUT_PHONE_UPDATE_CALL = 'setPhoneShow';
export const MUT_PHONE_DELETE_CALL = 'mutPhoneDeleteCall';
export const MUT_PHONE_CALL_SELECT = 'mutPhoneCallSelect';
export const MUT_PHONE_HIDE = 'setPhoneHide';
export const SAVE_SCREEN_SIZE = 'saveScreenSize';
export const MUT_SET_CALL_STATUS = 'mutSetCallStatus'
export const MUT_UPDATE_ANSWERED_CALL_ID = 'mutUpdateAnsweredCallId'
export const MUT_PHONE_SET_CALL_DELETE_TIMEOUT = 'mutPhoneSetCallDeleteTimeout'
export const MUT_PHONE_SET_CALL_TRANSITED = 'mutPhoneSetCallTransited'
export const MUT_PHONE_CONF_LIST_UPDATE = 'mutPhoneConfListUpdate'
export const MUT_PHONE_SET_CALL_MOS = 'mutPhoneSetCallMos'

/** Locations **/
export const MUT_SET_LOCATION_CONTACT = 'setLocationsContacts';
export const MUT_DELETE_LOCATION_CONTACT = 'deleteLocationsContacts';
export const MUT_SET_LOCATIONS_CONFIG = 'setLocationsConfig';

/** ClientData **/
export const MUT_SET_NEW_CLIENT_INFO = 'setNewClientInfo';
export const MUT_SET_UPDATE_STATUS = 'setUpdateStatus';
export const MUT_SET_CLIENT_DOWNLOAD_SIZE = 'setClientDownloadSize';
export const MUT_SET_CLIENT_DOWNLOAD_CURRENT_SIZE = 'setClientDownloadCurrentSize';
export const MUT_SET_LOCALE = 'setLocale'
export const MUT_SET_CLIENT_INFO = 'mutSetClientInfo'
export const MUT_SET_CURRENT_TIME = 'mutSetCurrentTime'
export const MUT_SET_NEXT_NEW_CLIENT_REQUEST = 'mutSetNextNewClientRequest'
export const MUT_SET_NEW_CLIENT_INFO_VIEWED = 'mutSetNewClientInfoViewed'
export const MUT_SET_TIMER_CHECK_UPDATE = 'mutSetTimerCheckUpdate'
export const MUT_SET_CLIENT_CHANGES = 'mutSetClientChanges'
export const MUT_SET_LAST_TIME_ACTIVE = 'mutSetLastTimeActive'
export const MUT_SET_FONTS_SETTINGS = 'mutSetFontsSettings'

/** Info **/
export const MUT_INFO_PUSH = 'infoPush';
export const MUT_INFO_REPLACE = 'infoReplace';
export const MUT_INFO_REPLACE_LAST = 'infoReplaceLast';
export const MUT_INFO_BACK = 'infoBack';
export const MUT_INFO_CLEAR = 'infoClear';
export const MUT_SET_FIRST_TIME_OPENED = 'mutSetFirstTimeOpened'
export const MUT_SET_COMPACT_MODE = 'mutSetCompactMode'
export const MUT_SET_INFO_MUST_BE_OPENED = 'mutSetInfoMustBeOpened'

/** Modals **/
export const MUT_MODAL_OPEN = 'modalOpen';
export const MUT_MODAL_CLOSE = 'modalClose';

/** Notification **/
export const ADD_NOTIFICATION = 'addNotificationData';
export const CLEAR_STACK_NOTIFICATION = 'clearNotificationData';
export const UPDATE_CUSTOM_NOTIFICATION_DATA = 'updateCustomNotificationData';
export const NOTIFICATION_ELECTRON_STATUS = 'notificationElectronStatus';
export const NOTIFICATION_DEFAULT_STATUS = 'notificationDefaultStatus';
export const DELETE_SYSTEM_NOTIFICATION = 'deleteSystemNotificcation'
export const ADD_SYSTEM_NOTIFICATION = 'addSystemNotificcation'

/** Radio **/
export const MUT_SET_CURRENT_RADIO_INDEX = 'setRadio'
export const MUT_SET_CURRENT_RADIO = 'setRadio'
export const MUT_SET_RADIO_SETTINGS = 'setRadioSettings'

/** RcRadio **/
export const MUT_RC_RADIO_CONNECTION_STATUS = 'setRcRadioGrantStatus'
export const MUT_RC_RADIO_SET_CHANNEL_ID = 'setRcRadioChannelId'
export const MUT_RC_RADIO_SET_CHANNEL_STATUS = 'setRcRadioChannelStatus'
export const MUT_RC_RADIO_SET_PTT_STATUS = 'setRcRadioPttStatus'
export const MUT_RC_RADIO_SET_GRANT_ERROR = 'setRcRadioGrantError'
export const MUT_RC_RADIO_SET_CH_GRANT_CID = 'setRcRadioChGrantCid'
export const MUT_RC_RADIO_SET_CH_CID_STATUS = 'setRcRadioChCidStatus'
export const MUT_RC_RADIO_SET_EXIT_STATUS = 'setRcRadioExitStatus'
export const MUT_RC_RADIO_SET_TOKEN = 'setRcRadioToken'

/** Tetra **/
export const MUT_TETRA_SET_CLIENT_PARAMS = 'setClientParams'
export const MUT_TETRA_SET_CONNECT_STATUS = 'setConnectStatus'
export const MUT_TETRA_SET_LINK_STATUS = 'setLinkStatus'
export const MUT_TETRA_SET_CONNECT_ERROR = 'setConnectError'
export const MUT_TETRA_SET_LINK_CREATED = 'setLinkStatus'
export const MUT_TETRA_SET_LOCATED = 'setLocated'
export const MUT_TETRA_SET_REJECTED = 'setRejected'
export const MUT_TETRA_SET_GROUPS_ATTACHED = 'setGroupsAttached'
export const MUT_TETRA_SET_CALL = 'setCallStatus'
export const MUT_TETRA_SET_TX_GRANT = 'setTxGrant'
export const MUT_TETRA_SET_GROUPS = 'setGroups'
export const MUT_TETRA_SET_ACTIVE_GROUP = 'setActiveGroup'
export const MUT_TETRA_SET_PTT_STATUS = 'setPTTState'
export const MUT_TETRA_SET_TOKEN = 'setToken'
export const MUT_TETRA_SET_SEARCHED_USERS = 'mutTetraSetSearchedUsers'
export const MUT_TETRA_UPDATE_USERS_LIST = 'mutTetraUpdateUsersList'
export const MUT_TETRA_ADD_RECENT_USER = 'mutTetraAddRecentUser'
export const MUT_TETRA_SET_GROUP_USERS = 'mutTetraSetGroupUsers'
export const MUT_TETRA_SET_USER_STATUS = 'mutTetraSetUserStatus'
export const MUT_TETRA_ADD_MESSAGE = 'mutTetraAddMessage'
export const MUT_TETRA_RESTORE_MESSAGES = 'mutTetraRestoreMessages'
export const MUT_TETRA_SET_MESSAGE_STATUS = 'mutTetraSetMessageStatus'
export const MUT_TETRA_CLEAR_UNWATCHED_MESSAGES = 'mutTetraClearUnwatchedMessages'
export const MUT_TETRA_SET_OPENED_CHAT_SSI = 'mutTetraSetOpenedChatSsi'
export const MUT_TETRA_ADD_DUPLEX_CALL = 'mutTetraAddDuplexCall'
export const MUT_TETRA_REMOVE_DUPLEX_CALL = 'mutTetraRemoveDuplexCall'

/** Video **/
export const MUT_SAVE_ROOM_ID = 'mutSaveRoomId'
export const MUT_SAVE_REMOTE_PROCESSING = 'mutSaveRemoteProcessing'
export const MUT_SAVE_INVITING_CID = 'mutSaveInvitingCid'
export const MUT_SAVE_CONTACTS = 'mutSaveCOntacts'
export const MUT_SAVE_OPTIONS = 'mutSaveOptions'
export const MUT_DELETE_REMOTE_PROCESSING = 'mutDeleteRemoteProcessing'
export const MUT_DELETE_CONTACT = 'mutDeleteContact'
export const MUT_SET_CREATOR_CONF = 'mutSetCreatorConf'
export const MUT_SET_ANSWER_STATUS = 'mutSetAnswerStatus'
export const MUT_STATE_CLEAR = 'mutStateClear'
export const MUT_SAVE_TURNSERVER = 'mutSaveTurnServer'
export const MUT_SYNC_STORE_ELECTRON = 'mutSyncStoreElectron'
export const MUT_ADD_CONTACT = 'mutAddContact'
export const MUT_ADD_CONTACTS = 'mutAddContacts'
export const MUT_SET_CONTACT = 'mutSetContact'
export const MUT_ADD_HOLDED_CONF = 'mutAddHodedConf'
export const MUT_DELETE_HOLDED_CONF = 'mutDeleteHoldedConf'
export const MUT_STATE_CHANGE = 'mutStateChange'
export const MUT_SET_HOLD_CONF = 'mutSetHoldConf'
export const MUT_SAVE_LOCALSTREAM = 'mutSaveLocalStream'
export const MUT_ADD_PEER_CONNECTION = 'mutAdPeerConnection'
export const MUT_DELETE_PEER_CONNECTION = 'mutDeletePeerConnection'
export const MUT_SET_ADD_DIALOG_OPENED = 'mutSetAddDialogOpened'

/** Asistant **/
export const MUT_UPDATE_CALL = 'mutUpdateCall'
export const MUT_ASSISTANT_UPDATE_CALL_PARTICIPANTS = 'mutAssistantUpdateCallParticipants'
export const MUT_UPDATE_STATUS = 'mutUpdateStatus'
export const MUT_UPDATE_MICROPHONE = 'mutUpdateMicrophone'
export const MUT_UPDATE_SPEAKER = 'mutUpdateSpeaker'
export const MUT_UPDATE_DND = 'mutUpdateDnd'
export const MUT_CLEAR_DATA = 'mutClearData'
export const MUT_SAVE_ASSISTANTS = 'mutSaveAssisant'
export const MUT_SAVE_CONTACT = 'mutSaveContact'
export const MUT_SAVE_ACTIVE_NUMBER = 'mutSaveActiveNumber'
export const MUT_DELETE_CALL = 'mutDeleteCall'
export const MUT_ADD_TRANSIT_ID = 'mutAddTransitId'

/** Phone controller **/
export const SET_DIALER_NUMBER ='setDialerNumber'
export const MUT_ADD_ANSWERED_CALL = "mutAddAnsweredCall"
export const MUT_SET_ACTIVE_ANSWERED_CALL = "mutSetActiveAnsweredCall"
export const MUT_DELETE_ANSWERED_CALL = "mutDeleteAnswerCall"
export const MUT_DELETE_INCOMING_CALL = "mutDeleteIncomingCall"
export const MUT_MOVE_ANSWERED_CALL_POSITION = "mutMoveAnsweredCallPosition"
export const MUT_SET_POSITION_ANSWERED_CALL = "mutSetyPositionAnsweredCall"
export const MUT_SET_MINIMIZED = "mutSetMinimized"
export const MUT_SET_PHONE_FULLSCREEN = "mutSetPhoneFullscreen"
export const MUT_SET_ANSWERED_CALL_STATUS = "mutSetAnsweredCallStatus"
export const MUT_UPDATE_CALL_PARTICIPANTS = "mutUpdateCallParticipants"


/** User Profile **/
export const MUT_SET_USER_PROFILE = 'mutSetProfile';
export const MUT_SET_REV = 'mutSetRev';
export const MUT_SET_DND = 'setDnd';
export const MUT_SET_DND_TIME_OUT = 'setDndTimeOut';
export const MUT_SET_CONFSCALENDAR = 'mutSetConfsCalendar'
export const MUT_DELETE_CONFSCALENDAR = 'mutDeleteConfsCalendar'

/** VideoConfNew **/
export const MUT_CONF_SET_DIALOG_OPENED = 'mutConfSetDialogOpened'
export const MUT_VC_CONF_ADD = 'mutVcConfAdd'
export const MUT_VC_CONF_REMOVE = 'mutVcConfRemove'
export const MUT_VC_SET_CONF_MINIMIZED = 'mutVcSetConfMinimized'
export const MUT_VC_SET_CONF_FULLSCREEN = 'mutVcConfFullscreen'

/** VideoConfAbstract **/
export const MUT_CONF_ADD = 'mutConfAdd'
export const MUT_CONF_REMOVE = 'mutConfRemove'
export const MUT_CONF_ADD_MEMBER = 'mutConfAddMember'
export const MUT_UPDATE_CONF = 'mutUpdateConf'
export const MUT_SET_CONF_INFO = 'mutSetConfInfo'
export const MUT_CONF_UPDATE_MEMBER = 'mutConfUpdateMember'
export const MUT_CONF_REMOVE_MEMBER = 'mutConfRemoveMember'
export const MUT_SET_CONF_SHARE = 'mutSetConfSharing'
export const MUT_SET_CONF_DEVICES = 'mutSetConfDevices'

/** VideoConfVideomost **/

export const MUT_SAVE_MSEXCHANGE_AUTH_RESULT = 'mutSaveMsExchangeAuthResult'




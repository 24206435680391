var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-publication-message",
      on: { click: _vm.showPublication },
    },
    [
      _vm.message.author
        ? _c("div", { staticClass: "forward" }, [
            _c("span", { staticClass: "fwd title" }, [
              _c("i", { staticClass: "fas fa-share" }),
              _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
            ]),
            _vm.fioById(_vm.message.author)
              ? _c("span", { staticClass: "fwd author" }, [
                  _vm._v(
                    _vm._s(_vm.$t("chat.author")) +
                      ": " +
                      _vm._s(_vm.fioById(_vm.message.author))
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "chat-publication-info" }, [
        !_vm.channel || !_vm.channel.info
          ? _c("div", { staticClass: "channel-name" }, [
              _vm._v(_vm._s(_vm.$t("publication"))),
            ])
          : _c("div", { staticClass: "channel-name" }, [
              _vm._v(_vm._s(_vm.channel.name)),
            ]),
        !_vm.channel
          ? _c("span", [_vm._v(_vm._s(_vm.$t("chat.no-permission")))])
          : _c("span", [_vm._v(_vm._s(_vm.$t("publication")))]),
      ]),
      _c("icon-image", {
        staticClass: "rectangular",
        attrs: { src: _vm.photo, text: _vm.name },
      }),
      _vm.channel
        ? _c("div", {
            staticClass: "title-publication",
            domProps: { innerHTML: _vm._s(_vm.titlePublication) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
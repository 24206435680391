var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "actions-container",
    _vm._l(_vm.getItems, function (item) {
      return _c(
        "list-item",
        {
          key: item.key,
          staticClass: "color-blue",
          attrs: {
            slot: "action",
            title: item.value,
            link: true,
            chevron: false,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.openSearchMedia(item.key)
            },
          },
          slot: "action",
        },
        [
          _c(
            "info-icon",
            {
              attrs: {
                slot: "media",
                "icon-color": "var(--icon-special-color)",
                "position-left": true,
              },
              slot: "media",
            },
            [
              _c("i", {
                staticClass: "fal",
                class: _vm.getIconClass(item),
                attrs: { slot: "default" },
                slot: "default",
              }),
            ]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }